import produce from "immer";
import {patchCardTypeAction} from "../../actions/diagramActions/patchCardAction";
import {patchCard} from "../../sagas/poll/patchCardAsync";
import {cardType} from "../../../../../types/types";
import {initStateType} from "../../reducer";
import {WritableDraft} from "immer/dist/types/types-external";

export const patchCardReducer = (state: initStateType, action: patchCardTypeAction) =>
  produce(state, (draft: WritableDraft<initStateType>) => {

    const newCards = () => {
      return state.cards.map((item: cardType) => {
        if (item.id === action.payload.card_id) {
          return {
            ...item,
            [action.payload.type]: action.payload.property[action.payload.type]
          }
        }
        return {...item}
      })
    }

    const newDetails = () => {
      return {
        ...state.detailsCard,
        [action.payload.type]: action.payload.property[action.payload.type]
      }
    }

    switch (action.payload.type) {
      case patchCard.position:
        draft.cards = newCards()
        break
      case patchCard.full_name:
        draft.cards = newCards()
        break
      case patchCard.color_card:
        draft.cards = newCards()
        break
      case patchCard.comments:
        draft.cards = newCards();
        if (Object.keys(state.detailsCard).length !== 0) {
          draft.detailsCard = newDetails()
        }
        break
      case patchCard.status:
        draft.cards = newCards()
        break
    }


  })
