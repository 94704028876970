import { useState } from "react";
import { Checkbox } from "../../../../ui-kit";
import * as Image from "../../images";

export type Props = {
  onChange: any;
  errorText: string;
};

export const InputPassword = (props: Props) => {
  const { onChange, errorText } = props;

  const [checked, setChecked] = useState(false);

  const togglePasswordVisibility = (value: boolean) => {
    setChecked(value);
  };

  const inputType = `${checked ? "text" : "password"}`;
  return (
    <>
      <div className="authForm__inputWrapper">
        <div className="authForm__icon">
          <Image.Lock />
        </div>
        <input
          name="password"
          type={inputType}
          className="authForm__input"
          placeholder="Пароль"
          onChange={onChange}
          required
          minLength={8}
          autoComplete="new-password"
        />
        <Checkbox
          ImageOn={Image.Eye}
          ImageOff={Image.EyeOff}
          onChange={togglePasswordVisibility}
        />
      </div>
      <span className="validation_error">{errorText && errorText}</span>
    </>
  );
};
