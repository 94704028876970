import {call, put, takeEvery} from "@redux-saga/core/effects";
import {
  GENERATE_POST_FIELDS_ACTION,
  GET_DETAILS_CARD_ACTION,
  GET_DETAILS_CARD_SAGA,
} from "../../actionTypes";
import {api} from "../../../../../api/restClient";
import {getDetails, getDetailsCardType} from "../../actions/diagramActions/getDetailsCard";
import {detailsCardType} from "../../../../../types/detailsCardTypes";
import {generatePostFields, generatePostFieldsType} from "../../actions/diagramActions/generatePostFields";


const getApiDetailsCard = (getObj) => {
  const {cardId, ordmId} = getObj
  return api.get(`v1/organigram/${ordmId}/cards/${cardId}/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}


function* getDetailsCardAsync(action) {
  try {
    const getObj = {cardId: action.payload.card_id, ordmId: action.payload.ordinagram_id}
    const details: detailsCardType = yield call(getApiDetailsCard, getObj)
    const getDetailsCardAction: getDetailsCardType = {
      type: GET_DETAILS_CARD_ACTION,
      payload: {
        card_id: getObj.cardId,
        ordinagram_id: getObj.ordmId,
        details: details,
        requestType: ""
      }
    }


    const generatePostFieldsAction: generatePostFieldsType = {
      type: GENERATE_POST_FIELDS_ACTION,
      payload: {
        card_id: getObj.cardId,
        ordinagram_id: getObj.ordmId,
        fields: details.fields
      }
    }
    yield put(getDetails(getDetailsCardAction))
    yield put(generatePostFields(generatePostFieldsAction))
  } catch (err) {
    alert('Медленная скорость интернета для загрузки деталей карточки')
  }

}

export function* getDetailsCardSagas() {
  yield takeEvery(GET_DETAILS_CARD_SAGA, getDetailsCardAsync)
}