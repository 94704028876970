import { api } from './restClient'

export const handleSetUser = (tokenData, emailData) => {
    localStorage.setItem('user', JSON.stringify(tokenData.token))
    localStorage.setItem('userEmail', JSON.stringify(emailData))
    api.setUserToken(tokenData.token)
}

export const handleLogin = async (data) => {
    try {
        await api.post('v1/authentication/token/obtaining/', data).then(res => {
            handleSetUser(res.data, data.email)
        })
    } catch (error) { return 'Неверно указана почта или пароль' }
}

export const handleRegister = async (data) => {
    try {
        await api.post('v1/accounts/registration/', data).then(res => {
            handleLogin(data)
        })
    } catch (error) { return 'Пользователь с таким Email уже зарегистрирован' }
}

export const verifyToken = async (data: string) => {
    const token = {
        token: data
    }
    try {
        return await api.post('v1/authentication/token/verification/', token).then(res => {
            api.setUserToken(data)
            return res
        })
    } catch (error) { console.log(error) }
}

export const sendEmail = async (data) => {
    try {
        return await api.post('v1/accounts/sendemail/', data)
    } catch (error) { console.log(error) }
}

export const setNewPassword = async (data, secret) => {
    try {
        return await api.post(`v1/accounts/newpassword/${secret}/`, data)
    } catch (error) { console.log(error) }
}

export const googleAuth = async () => {
    try {
        const response = await api.get('v1/accounts/googleauth/')
        return response.data
    } catch (error) { console.log(error) }
}
