import createReducer from 'store/createReducer'

import * as reducers from './reducers'
import * as t from './actionTypes'
import {
  avatarType,
  cardType,
  connectLinesType,
  ordinagramType,
  previewPrintType,
  userType
} from "../../../types/types";
import {detailsCardType, DocumentsType, FieldsType} from "../../../types/detailsCardTypes";

export type initStateType = {
  ordinagrams: ordinagramType[]
  cards: cardType[]
  detailsCard: detailsCardType | {},
  fieldsDetailsCard: FieldsType[]
  documentsCard: DocumentsType[]
  connectLines: connectLinesType[]
  avatars: avatarType[]
  activeOrdinagramId: number
  previewPrint: previewPrintType
  leftSidePanelActive: boolean
  rightSidePanelActive: boolean
  isUserCheckStarted: boolean
  loading: boolean
  user: userType
}

const initState: initStateType = {
  ordinagrams: [],
  cards: [],
  detailsCard: {},
  fieldsDetailsCard: [],
  documentsCard: [],
  connectLines: [],
  avatars: [],
  activeOrdinagramId: null,
  previewPrint: {
    A3: false,
    A4: false,
    previewMode: false
  },

  leftSidePanelActive: false,
  rightSidePanelActive: false,
  isUserCheckStarted: false,
  loading: false,

  user: {
    email: '',
    avatar: '',
    name: '',
  }
}

const homePageReducer = createReducer(initState, {
  [t.TOGGLE_LEFT_SIDE_PANEL]: reducers.toggleLeftSidePanelReducer,
  [t.TOGGLE_RIGHT_SIDE_PANEL]: reducers.toggleRightSidePanelReducer,

  [t.START_USER_CHECK]: reducers.startUserCheckReducer,
  [t.FINISH_USER_CHECK]: reducers.finishUserCheckReducer,

  [t.SET_USER_EMAIL]: reducers.setUserEmailReducer,
  [t.SET_FULL_USER]: reducers.setFullUserReducer,

  [t.PREVIEW_PRINT_ACTION]: reducers.previewPrintReducer,

  [t.GET_ORDINAGRAMS_ACTION]: reducers.getOrdmReducer,
  [t.CREATE_ORDINAGRAM_ACTION]: reducers.createOrdmReducer,
  [t.GET_CARDS_ACTION]: reducers.getCardsReducer,
  [t.GET_DETAILS_CARD_ACTION]: reducers.getDetailsCardReducer,
  [t.PATCH_CARD_ACTION]: reducers.patchCardReducer,
  [t.PATCH_DETAILS_ACTION]: reducers.patchDetailsCardReducer,
  [t.GENERATE_POST_FIELDS_ACTION]: reducers.generatePostFieldsReducer,
  [t.GET_DOCUMENTS_ACTION]: reducers.getDocumentsReducer,
})

export default homePageReducer