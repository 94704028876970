import {all, put, call, takeEvery, select} from "redux-saga/effects";
import {GET_CARDS_ACTION, POST_CONNECTION_LINE_SAGA} from "../../actionTypes";
import {connectLinesType} from "../../../../../types/types";
import {api} from "../../../../../api/restClient";
import {getCardsAction} from "../../actions/diagramActions/getCards";

const postApiCard = (postObj) => {
  const {params, ordmId} = postObj
  return api.post(`v1/organigram/${ordmId}/connection_line/`, params)
    .catch((err) => console.log(err))
}

const getApiCards = (id) => {
  return api.get(`v1/organigram/${id}/cards/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

function* postConnectLineAsync(action) {
  const line: connectLinesType = action.payload.data
  const ordmId = action.payload.ordinagram_id
  const postObj = {params: line, ordmId: ordmId}
  yield call(postApiCard, postObj)
  const response = yield call(getApiCards, ordmId)
  yield put(getCardsAction({
    type: GET_CARDS_ACTION,
    payload: {data: response, ordinagramId: ordmId}
  }))
}

export function* postConnectLineSagas() {
  yield takeEvery(POST_CONNECTION_LINE_SAGA, postConnectLineAsync)
}