import {all, call} from "@redux-saga/core/effects";

import {
  getOrgmsSagas,
  getCardsSagas,
  getDetailsCardSagas,
  createNewOrdSagas,
  createNewCardSagas,
  patchCardSagas,
  putDetailsSagas,
  putFieldsSagas,
  patchDetailsCardSagas,
  postConnectLineSagas,
  postAvatarSagas,
  postDocumentSagas,
  deleteCardSagas,
  deleteOrdmSagas,
  deleteFieldSagas,
  deleteDocumentSagas,
  disconnectConnectionLineSagas,
} from "./poll";

export function* homeListSagas() {
  yield all([
    call(getOrgmsSagas),
    call(getCardsSagas),
    call(getDetailsCardSagas),
    call(createNewOrdSagas),
    call(createNewCardSagas),
    call(patchCardSagas),
    call(patchDetailsCardSagas),
    call(putDetailsSagas),
    call(putFieldsSagas),
    call(postConnectLineSagas),
    call(postAvatarSagas),
    call(postDocumentSagas),
    call(deleteCardSagas),
    call(deleteOrdmSagas),
    call(deleteFieldSagas),
    call(deleteDocumentSagas),
    call(disconnectConnectionLineSagas),
  ])
}