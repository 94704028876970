import React, {FC} from "react";
import styles from "../details-card.module.scss";
import {changeType} from "../constants";
import {useTranslation} from "react-i18next";

type CallSingType = {
  call_sing: string
  objectPutFormation: (e, changeType: string) => void
}

const CallSing: FC<CallSingType> = ({
                                      call_sing,
                                      objectPutFormation
                                    }) => {

   const [t] = useTranslation();

  return (
    <input onChange={e => objectPutFormation(e, changeType.call_sign)}
           className={`${styles.input} ${styles.input_callSing}`}
           type="text"
           placeholder={t('card_callsign')}
           defaultValue={call_sing}
    />

  )
}

export default CallSing
