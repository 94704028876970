import * as t from '../../actionTypes'

export const showError = (text) => {
  return {
    type: t.SHOW_ERROR,
    payload: text,
  }
}

export const hideError = () => {
  return {
    type: t.HIDE_ERROR,
  }
}