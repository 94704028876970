import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {appGlobalStore} from "../../store/root-reducer";
import Modal from '@material-ui/core/Modal';
import {api} from "../../api/restClient";
import styles from './details-card.module.scss'

import {ava, documentsSvg, kebabMenuSvg, messageCircleSvg} from "./images";

import {
  GET_CARDS_ACTION,
  GET_DETAILS_CARD_ACTION, PATCH_CARD_SAGA, PATCH_DETAILS_CARD_SAGA,
  POST_AVATAR_SAGA,
  PUT_DETAILS_CARD_SAGA
} from "../../pages/home/redux-sagas/actionTypes";
import FullNameInput from "./components/full-name";

import {cardType} from "../../types/types";
import {detailsCardType} from "../../types/detailsCardTypes";
import {changeType} from "./constants";
import {getDetails, getDetailsCardType} from "../../pages/home/redux-sagas/actions/diagramActions/getDetailsCard";
import CallSing from "./components/call-sing";
import Comments from "./components/comments/comments";
import WorkingPosition from "./components/working-position";
import PersonalData from "./components/personal-data/personal-data";
import Documents from "./components/documents/documents";
import {patchCard} from "../../pages/home/redux-sagas/sagas/poll/patchCardAsync";
import {modalType} from "../../components/diagrams/Main";
import MenuCard from "../menu-card/menu-card";
import {Loader} from "../../ui-kit/loader";
import {getCardsAction, getCardsActionType} from "../../pages/home/redux-sagas/actions/diagramActions/getCards";
import {useTranslation} from "react-i18next";

type DataCardType = {
  setOpenModalDetail: (boolean) => void,
  open: boolean,
  card: cardType,
  showCardModal: (open, card: cardType, type) => void
}


export const DetailsCard: FC<DataCardType> = ({
                                                setOpenModalDetail,
                                                open = false,
                                                showCardModal,
                                                card,
                                              }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation();
  const details: detailsCardType = useSelector(({homePage}: appGlobalStore) => homePage.detailsCard)
  const initialElements = useSelector(({homePage}: appGlobalStore) => homePage.cards)
  const connectLines = useSelector(({homePage}: appGlobalStore) => homePage.connectLines)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    details?.id !== undefined && setLoading(false)
  },)


  const handleClose = () => {
    setOpenModalDetail(false);
    dispatch(getDetails({
      type: GET_DETAILS_CARD_ACTION,
      payload: {
        card_id: details.card_id,
        ordinagram_id: details.ordinagram_id,
        details: {},
      }
    } as getDetailsCardType))
  };

  const objectPatchFormation = (value, type) => {
    let newDetails = {}
    let urlType: string = ""
    switch (type) {
      case patchCard.comments:
        urlType = patchCard.comments
    }
    dispatch({
      type: PATCH_CARD_SAGA,
      payload: {
        card_id: card.id,
        property: value,
        ordinagram_id: card.ordinagram_id,
        type: urlType
      }
    })
  }


  const objectPutFormation = (e, type) => {
    let newDetails = {}
    let newCards = []
    const currentValue = e.target.value
    let urlType = ""
    switch (type) {
      case changeType.full_name:
        urlType = 'details'
        newDetails = {
          position: details.position,
          full_name: currentValue
        } as detailsCardType
        newCards = initialElements.map((item: cardType) => {
          if (item.id === details.card_id) return {...item, full_name: currentValue}
          return item
        })
        break
      case changeType.call_sign:
        urlType = 'details'
        newDetails = {
          position: details.position,
          call_sign: currentValue
        } as detailsCardType
        newCards = initialElements.map((item: cardType) => {
          if (item.id === details.card_id) return {...item, call_sign: currentValue}
          return item
        })
        break
      case changeType.working_position:
        urlType = 'details'
        newDetails = {
          position: details.position,
          working_position: currentValue
        } as detailsCardType
        newCards = initialElements.map((item: cardType) => {
          if (item.id === details.card_id) return {...item, working_position: currentValue}
          return item
        })
        break
    }

    dispatch<getCardsActionType>(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {
          cards: newCards,
          connection_lines: connectLines
        },
        ordinagramId: details.ordinagram_id
      }
    }))

    dispatch({
      type: PUT_DETAILS_CARD_SAGA,
      payload: {
        card_id: details.card_id,
        ordinagram_id: details.ordinagram_id,
        details: newDetails,
        urlType,
      }
    } as getDetailsCardType)
  }


  const handleImageUpload = (e, card: cardType) => {
    const files = e.target.files
    const myImage = files[0]
    let formData = new FormData()
    formData.append('id', card.id)
    formData.append('original_image', myImage)
    const imageType = /image.*/
    if (!myImage?.type.match(imageType) && myImage !== undefined) {
      alert('Извините, разрешены только изображения')
      return
    }
    dispatch({type: POST_AVATAR_SAGA, payload: {card_id: card.id, ordinagram_id: card.ordinagram_id, formData}})
  }


  const del = () => {
    api.delete(`v1/avatar/${card.id}/`)
      .catch((err) => console.log(err))
  }


  return (
    <div>
      <div style={{position: 'relative'}}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >

          <div className={styles.modal}>
            {loading ?
              <>
                <div className={styles.status}
                     style={{background: '#ffffff'}}/>
                <div className={styles.modal__item}>
                  <Loader/>
                </div>
              </>
              :
              <>
                <div className={styles.status}
                     style={{background: details?.color_card || '#ffffff'}}/>
                <div className={styles.modal__item}>
                  <div className="d-flex justify-content-end">
                    <CallSing call_sing={details?.call_sign} objectPutFormation={objectPutFormation}/>
                    <img
                      className={styles.menu}
                      src={kebabMenuSvg}
                      alt="menu"
                      onClick={() => card.openMenuDetails ?
                        showCardModal(false, card, modalType.menuDetails) : showCardModal(true, card, modalType.menuDetails)}
                      onTouchStart={() => card.openMenuDetails ?
                        showCardModal(false, card, modalType.menuDetails) : showCardModal(true, card, modalType.menuDetails)}
                      onKeyUp={(e) => {
                        card.openMenuDetails && e.key === "Escape" && showCardModal(false, card, modalType.menuDetails)
                      }}
                    />
                  </div>
                  <div className={styles.headerInfo}>
                    <div className={styles.avatarWrapper}>
                      <input onChange={(e) => handleImageUpload(e, card)} name="avatar" id="avatarFile"
                             className={styles.avatarInput}
                             type="file"/>
                      <label htmlFor="avatarFile">
                        <img className={styles.avatarImage} src={details.avatar_image || ava} alt="avatar"/>
                      </label>
                    </div>
                    <div className="ml-15" style={{width: '100%'}}>
                      <FullNameInput full_name={details?.full_name} objectPutFormation={objectPutFormation}/>
                      <WorkingPosition working_position={details?.working_position}
                                       objectPutFormation={objectPutFormation}/>
                    </div>
                  </div>

                  <PersonalData details={details}/>
                  <h4 className="mt-25 mb-15 d-flex align-items-center ">
                    <span className="d-flex align-items-center"><img src={messageCircleSvg} alt="message"/></span>
                    {t('notes')}
                  </h4>
                  <Comments objectPatchFormation={objectPatchFormation} comments={details?.comments}/>
                  <h4 className="mt-25 mb-15 d-flex align-items-center ">
                    <span className="d-flex align-items-center"><img src={documentsSvg} alt="doc"/></span>
                    {t('documents')}
                  </h4>
                  <Documents card={card}/>
                </div>
              </>
            }
            {card.openMenuDetails && <MenuCard setOpenModalDetail={setOpenModalDetail} card={card}/>}
          </div>
        </Modal>
      </div>
    </div>
  );
}
