import React, {FC, useEffect, useState} from "react";
import styles from './ducuments.module.scss';
import {cardType} from "../../../../types/types";
import {detailsCardType, DocumentsType} from "../../../../types/detailsCardTypes";
import {useDispatch, useSelector} from "react-redux";
import {
  DELETE_DOCUMENT_SAGA,
  PATCH_DOCUMENT_SAGA,
  POST_DOCUMENT_SAGA
} from "../../../../pages/home/redux-sagas/actionTypes";
import DateCalendar from "../../../../utils/calendar/calendar";
import {appGlobalStore} from "../../../../store/root-reducer";
import {deleteSvg, labelRedSvg, labelYellowSvg, plusSquareSvg, shareSvg} from "../../images";
import Share from "../../../share/share";
import {indicatorLabelColor} from "../../../../utils/indicatorLabelColor";
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';

type DocumentsComponentType = {
  card: cardType
}

const Documents: FC<DocumentsComponentType> = ({
                                                 card,
                                               }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const details: detailsCardType = useSelector(({homePage}: appGlobalStore) => homePage.detailsCard)
  const [shareOpen, setShareOpen] = useState(false)
  const [activeIdDoc, setActiveIdDoc] = useState(null)
  const [openCalendar, setOpenCalendar] = useState({id: null, open: false})
  const [patchDateDocument, setPatchDateDocument] = useState<DocumentsType>({
    id: null,
    completion_date: "",  //YYYY-MM-DD
  } as DocumentsType)

  useEffect(() => {
    patchDateDocument.id !== null && patchDateDocument.completion_date !== '' && !openCalendar.open &&
    dispatch({
      type: PATCH_DOCUMENT_SAGA,
      payload: {
        card_id: card.id,
        ordinagram_id: card.ordinagram_id,
        document: patchDateDocument
      }
    })
  }, [patchDateDocument])


  const handleShare = (id) => {
    setActiveIdDoc(id)
    setShareOpen(shareOpen => !shareOpen)
    setOpenCalendar({id: null, open: false})
  }


  const handleCalendar = (id) => {
    setActiveIdDoc(id)
    setPatchDateDocument({...patchDateDocument, id: id})
    setOpenCalendar({id: null, open: true})
  }


  const deleteDocument = (id) => {
    dispatch({
      type: DELETE_DOCUMENT_SAGA,
      payload: {
        card_id: card.id,
        ordinagram_id: card.ordinagram_id,
        document_id: id
      }
    })
  }


  const handleDocumentUpload = (event, card: cardType) => {
    const files = event.target.files
    const file = files[0]
    const formData = new FormData()
    formData.append('file', file)
    dispatch({
      type: POST_DOCUMENT_SAGA,
      payload: {
        card_id: card.id,
        ordinagram_id: card.ordinagram_id,
        document: formData
      }
    })
  }


  const onDocumentClick = (document) => {
    localStorage.setItem('document', JSON.stringify(document))
  }

  return (
    <div style={{position: "relative"}}>
      {
        details?.documents?.map((item: DocumentsType, index) => (
          <div key={item.id} className={styles.document}>
            <div style={{ cursor: 'pointer' }} onClick={() => onDocumentClick(item)}>
              <Link
                  style={{ cursor: 'pointer' }}
                  target={'_blank'}
                  className={`${styles.text} ${styles.text_file}`}
                  to={'/file/view'}
              >
                {`${index + 1}. ${item.name}`}
              </Link>
            </div>

            <div className="d-flex align-items-center justify-content-end" style={{position: "relative"}}>
              {indicatorLabelColor(item?.time_before_expiration) === 0 && <img src={labelRedSvg} alt="label-red"/>}
              {indicatorLabelColor(item?.time_before_expiration) === (1 || 2) &&
              <img src={labelYellowSvg} alt="label-yellow"/>}
              <p className={`${styles.text} ${styles.text_date}`}
                 style={{cursor: "default"}}>{item.time_before_expiration}</p>
              <p onClick={() => handleCalendar(item.id)}
                 className={styles.text}
                 style={{
                   color: activeIdDoc === item.id && openCalendar && '#53A9D7',
                   marginLeft: 15
                 }}
              >{item.completion_date ? item.completion_date.split('-').reverse().join('.') : "Дата завершения"}</p>
              <img onClick={() => handleShare(item.id)} className={styles.share} src={shareSvg} alt="share"/>
              <img onClick={() => deleteDocument(item.id)}
                   className={styles.delete}
                   src={deleteSvg} alt="del"/>
              {shareOpen && activeIdDoc === item.id && <Share setShareOpen={setShareOpen}
                                                              urlDocument={item.file}
                                                              fileName={item.name}/>}
              {openCalendar.open && activeIdDoc === item.id && <DateCalendar setOpenCalendar={setOpenCalendar}
                                                                             setPatchDateDocument={setPatchDateDocument}
                                                                             patchDateDocument={patchDateDocument}
                                                                             type="document"
              />}
            </div>
          </div>
        ))
      }
      <div className="d-flex align-items-center mb-5" style={{position: "relative"}}>

        <img src={plusSquareSvg} alt="square" style={{width: 16, height: 16}}/>
        <input onChange={(e) => handleDocumentUpload(e, card)}
               className={styles.inputFile}
               type="file"
               id="documentsFile"/>
        <label htmlFor="documentsFile">
          <p className={`${styles.add} ${styles.add_action}`}>{t('add_btn')}</p>
        </label>
      </div>
    </div>
  )
}

export default Documents
