import { Checkbox } from "../../../../../ui-kit";
import { EmptyCheckbox, CheckedCheckbox } from "../../images";
import "./userCard.scss";
import {useTranslation} from "react-i18next";

export type Props = {
  name: string;
  callSign?: string;
  department?: string;
  disableCheckbox: boolean;
  onChange: any;
  color: string;
  isFiltered: any;
};

export const UserCard = (props: Props) => {
  const {
    name,
    callSign,
    department,
    disableCheckbox,
    onChange,
    color,
    isFiltered,
  } = props;

  const [t] = useTranslation();

  return (
    <div className="worker__card">
      <div className="worker__info">
        <p className="worker__name">{name ? name : t('no_name')}</p>
        <p className="worker__call-sign">{callSign ? callSign : t('without_caller')}</p>
        <div
          className="worker__department-wrapper"
          style={{ backgroundColor: `${color}` }}
        >
          <p className="worker__department">{department || t('without_department')}</p>
        </div>
      </div>
      {!isFiltered && (
        <Checkbox
          ImageOn={CheckedCheckbox}
          ImageOff={EmptyCheckbox}
          onChange={onChange}
          disabled={!disableCheckbox}
        />
      )}
    </div>
  );
};
