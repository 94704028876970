import produce from "immer";
import {initStateType} from "../../reducer";
import {patchDetailsActionType} from "../../actions/diagramActions/patchDetailsCardAction";

export const patchDetailsCardReducer = (state: initStateType, action: patchDetailsActionType) =>
  produce(state, (draft) => {
    const newDetails = () => {
      return {
        ...state.detailsCard,
        [action.payload.type]: action.payload.property[action.payload.type]
      }
    }

    draft.detailsCard = newDetails()
  })