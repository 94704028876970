import React, {FC, useEffect} from "react";
import styles from './choice-ordinagram.module.scss'
import {ordinagramType} from "../../types/types";
import {useSelector} from "react-redux";
import {appGlobalStore} from "../../store/root-reducer";
import {deleteSvg} from "../../components/control-panel/images";
import {useTranslation} from "react-i18next";

type ChoiceOrdType = {
  ordinagrams?: ordinagramType[],
  onClickOpenModalCreate: () => void,
  choiceOrdinagram: (card: ordinagramType) => void,
  showConfirm: (card: ordinagramType, id) => void,
}


const ChoiceOrdinagram: FC<ChoiceOrdType> = ({
                                               ordinagrams,
                                               onClickOpenModalCreate,
                                               choiceOrdinagram,
                                               showConfirm,
                                             }) => {

  const activeOrdinagramId = useSelector(({homePage}: appGlobalStore) => homePage.activeOrdinagramId)
    const [t] = useTranslation();


  return (
    <div className={styles.modOrgm}>
      <p className={`${styles.text} ${styles.text_choice}`}>{t('choose_an_organigram')}</p>
      <ul className={styles.list}>
        {ordinagrams?.map(ordm =>
          <li className={styles.card}
              style={{
                background: ordm.id === activeOrdinagramId && '#b4def4',
                borderRadius: ordm.id === activeOrdinagramId && 4,
              }}>
            <p key={`${ordm?.id}_${ordm?.title}`}
               onClick={() => choiceOrdinagram(ordm)}
               className={`${styles.text} ${styles.text_bark}`}
            >{ordm.title}</p>
            <img onClick={() => showConfirm(ordm, null)}
                 className={styles.delete}
              src={deleteSvg} alt="del"/>
          </li>
        )
        }
      </ul>
      <p className={`${styles.text} ${styles.text_create}`}
         onClick={onClickOpenModalCreate}
      >{t('create_new')}</p>
    </div>
  )
}

export default ChoiceOrdinagram;
