import React, {useCallback, useEffect, useState} from 'react';
import ReactFlow, {Controls, updateEdge, addEdge, Handle, Node, MiniMap} from 'react-flow-renderer';
import './main.scss';
import styles from './card/card.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {appGlobalStore} from "../../store/root-reducer";
import Card from "./card/card";
import {
  DISCONNECT_CONNECTION_LINE_SAGA,
  GET_CARDS_ACTION, GET_DETAILS_CARD_SAGA, GET_ORDINAGRAM_SAGA, GET_ORDINAGRAMS_ACTION,
  PATCH_CARD_SAGA,
  POST_CARD_SAGA, POST_CONNECTION_LINE_SAGA
} from "../../pages/home/redux-sagas/actionTypes";
import {cardType, connectLinesType, ordinagramType, previewPrintType} from "../../types/types";
import {getCardsAction, getCardsActionType} from "../../pages/home/redux-sagas/actions/diagramActions/getCards";
import {patchCard} from "../../pages/home/redux-sagas/sagas/poll/patchCardAsync";
import {getOrdinagrams} from "../../pages/home/redux-sagas/actions/diagramActions/getOrdinagrams";
import CardPreview from "./card/card-preview/card-preview";
import {DetailsCard} from "../../modals/data-card";


export const addCardSide = {
  ADD_LEFT: 'ADD_LEFT',
  ADD_RIGHT: 'ADD_RIGHT',
  ADD_BOTTOM: 'ADD_BOTTOM',
}

export const modalType = {
  comments: 'comments',
  document: 'ducument',
  menuCard: 'menuCard',
  menuDetails: 'menuDetails',
  saveInput: 'saveInput',
}


const onLoad = (reactFlowInstance) => reactFlowInstance.fitView();

export default ({
                  toggleLeftSidePanel,
                  toggleRightSidePanel,
                  isLeftPanelOpen,
                  isRightPanelOpen,
                }) => {
  const dispatch = useDispatch()
  const initialElements = useSelector(({homePage}: appGlobalStore) => homePage.cards)
  const connectLines = useSelector(({homePage}: appGlobalStore) => homePage.connectLines)
  const activeOrdinagramId = useSelector(({homePage}: appGlobalStore) => homePage.activeOrdinagramId)
  const ordinagrams = useSelector(({homePage}: appGlobalStore): ordinagramType[] => homePage.ordinagrams)
  const previewPrint: previewPrintType = useSelector(({homePage}: appGlobalStore): previewPrintType => homePage.previewPrint)
  const loggedIn = useSelector(({authorization}: appGlobalStore): boolean => authorization.loggedIn)

  const [elements, setElements] = useState([]);
  const [activeOrdm, setActiveOrdm] = useState<ordinagramType>({} as ordinagramType)
  const [dataActiveCard, setDataActiveCard] = useState<cardType>({} as cardType)
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [nodeDragStart, setNodeDragStart] = useState({x: null, y: null})
  const [idDeletedLine, setIdDeletedLine] = useState(null)
  const [isAnimatedLine, setIsAnimatedLine] = useState({
    animated: false,
    newConnectLines: [],
  } as { animated: boolean, newConnectLines: connectLinesType[] })


  useEffect(() => {
    loggedIn && dispatch({type: GET_ORDINAGRAM_SAGA})
  }, [loggedIn])

  useEffect(() => {
    ordinagrams.length && activeOrdinagram()
    let items = initialElements.map((card: cardType, index) => {
      card.id === dataActiveCard.id && setDataActiveCard(card)
      return ({
        id: card.id,
        position: card.position,
        data: {
          label:
            <>
              {previewPrint.previewMode ? <CardPreview
                  card={card}
                /> :
                <Card
                  card={card}
                  elements={elements}
                  initialElements={initialElements}
                  onSubmitName={onSubmitName}
                  onSubmitComment={onSubmitComment}
                  onHandleInputName={onHandleInputName}
                  onAddLeft={onAddLeft}
                  onAddRight={onAddRight}
                  onAddBottom={onAddBottom}
                  showCardModal={showCardModal}
                  handleDoubleClickCard={handleDoubleClickCard}
                />
              }
            </>

        },
        inputFocus: false,
        type: 'input',
        className: previewPrint.previewMode ? `${styles.card} ${styles.card_preview}` : styles.card,
      })
    })
    let lines = connectLines.map((line: connectLinesType) => {
      return {
        ...line,
        style: {stroke: line.color}
      }
    })
    items = items.concat(lines)
    setElements(items)
  }, [initialElements, previewPrint.previewMode])


  useEffect(() => {
    document.addEventListener('keyup', closeModalEsc);
    return () => {
      document.removeEventListener("keyup", closeModalEsc);
    }
  }, [initialElements])

  useEffect(() => {
    animatedLines(isAnimatedLine)
  }, [isAnimatedLine.animated])


  useEffect(() => {
    deleteLines(idDeletedLine)
  }, [idDeletedLine])


  const closeModalEsc = (e) => {
    if (e.keyCode === 27) {
      showCardModal(false, dataActiveCard, null)
      !dataActiveCard.full_name && onSubmitName(e, dataActiveCard, '')
    }
  }


  const activeOrdinagram = () => {
    const obj = ordinagrams.find((item: ordinagramType): boolean => item.id === activeOrdinagramId) || null
    setActiveOrdm(obj)
  }


  // const onEdgeUpdate = (oldEdge, newConnection) =>
  //   setElements((els) => updateEdge(oldEdge, newConnection, els));


  const showCardModal = (open, card: cardType, type) => {
    setDataActiveCard(card)
    const newCards = initialElements.map((item: cardType) => {
      if (item.id === card.id) {
        switch (type) {
          case modalType.menuCard:
            return {
              ...item,
              openMenuCard: open,
              openMenuDetails: false,
              inputFocus: false,
              openDocument: false,
              openComments: false
            }
          case modalType.menuDetails:
            return {
              ...item,
              openMenuCard: false,
              openMenuDetails: open,
              inputFocus: false,
              openDocument: false,
              openComments: false
            }
          case modalType.saveInput:
            return {
              ...item,
              openMenuCard: false,
              openMenuDetails: false,
              inputFocus: open,
              openDocument: false,
              openComments: false
            }
          case modalType.document:
            return {
              ...item,
              openMenuCard: false,
              openMenuDetails: false,
              inputFocus: false,
              openDocument: open,
              openComments: false
            }
          case modalType.comments:
            return {
              ...item,
              openMenuCard: false,
              openMenuDetails: false,
              inputFocus: false,
              openDocument: false,
              openComments: open
            }
          case null:
            return {
              ...item,
              openMenuCard: false,
              openMenuDetails: false,
              inputFocus: false,
              openDocument: false,
              openComments: false
            }
          default:
            return {
              ...item,
              openMenuCard: true,
              openMenuDetails: false,
              inputFocus: true,
              openDocument: true,
              openComments: true
            }
        }
      }
      return {
        ...item,
        openMenuCard: false,
        openMenuDetails: false,
        inputFocus: false,
        openDocument: false,
        openComments: false
      }
    })
    const newOrdinagrams: ordinagramType[] = ordinagrams.map((ordm: ordinagramType): ordinagramType => {
      if (ordm.id === card.ordinagram_id) {
        return {...ordm, zoomOnScroll: open ? false : !open, isDraggableCard: open ? false : !open}
      }
      return {...ordm}
    })
    ordinagrams.length !== 0 && dispatch(getOrdinagrams({
      type: GET_ORDINAGRAMS_ACTION,
      payload: newOrdinagrams
    }))

    initialElements.length !== 0 && dispatch(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {
          cards: newCards,
          connection_lines: connectLines,
        },
        ordinagramId: Number(card.ordinagram_id)
      }
    }))
  }


  const onAddLeft = (event, card: cardType) => {
    const newOrdinagrams: ordinagramType[] = ordinagrams.map((ordm: ordinagramType): ordinagramType => {
      return {...ordm, zoomOnScroll: true, isDraggableCard: true}
    })
    dispatch({type: POST_CARD_SAGA, payload: {params: card, type: addCardSide.ADD_LEFT}})
    dispatch(getOrdinagrams({
      type: GET_ORDINAGRAMS_ACTION,
      payload: newOrdinagrams
    }))
  }


  const onAddRight = (event, card: cardType) => {
    const newOrdinagrams: ordinagramType[] = ordinagrams.map((ordm: ordinagramType): ordinagramType => {
      return {...ordm, zoomOnScroll: true, isDraggableCard: true}
    })
    dispatch({type: POST_CARD_SAGA, payload: {params: card, type: addCardSide.ADD_RIGHT}})
    dispatch(getOrdinagrams({
      type: GET_ORDINAGRAMS_ACTION,
      payload: newOrdinagrams
    }))
  }


  const onAddBottom = (event, card: cardType) => {
    const newOrdinagrams: ordinagramType[] = ordinagrams.map((ordm: ordinagramType): ordinagramType => {
      return {...ordm, zoomOnScroll: true, isDraggableCard: true}
    })
    dispatch({type: POST_CARD_SAGA, payload: {params: card, type: addCardSide.ADD_BOTTOM}})
    dispatch(getOrdinagrams({
      type: GET_ORDINAGRAMS_ACTION,
      payload: newOrdinagrams
    }))
  };


  const handleDoubleClickCard = (card: cardType) => {
    setOpenModalDetail(true);
    showCardModal(false, card, null)
    setDataActiveCard(card)
    dispatch({type: GET_DETAILS_CARD_SAGA, payload: {card_id: card.id, ordinagram_id: card.ordinagram_id}})
  };


  const onConnect = (params) => {
    const postConnectLine: connectLinesType = {
      source: Number(params.source),
      target: Number(params.target),
      type: activeOrdm.connect_line,
      color: activeOrdm.base_color,
    }
    params = {...params, type: activeOrdm.connect_line,}
    setElements((els) => addEdge(params, els))
    dispatch({type: POST_CONNECTION_LINE_SAGA, payload: {data: postConnectLine, ordinagram_id: activeOrdm.id}})
  };


  const onSubmitName = (e, card: cardType, value) => {
    e.preventDefault()
    value.length === 0 && document.forms["name1"]?.reset()
    document.forms["name1"]?.blur()
    dispatch({
      type: PATCH_CARD_SAGA,
      payload: {
        card_id: card.id,
        property: value,
        ordinagram_id: card.ordinagram_id,
        type: patchCard.full_name
      }
    })
    showCardModal(false, card, modalType.saveInput)
  };


  const onSubmitComment = (card: cardType, value) => {
    dispatch({
      type: PATCH_CARD_SAGA,
      payload: {
        card_id: card.id,
        property: value,
        ordinagram_id: card.ordinagram_id, type: patchCard.comments
      }
    })
    showCardModal(false, card, modalType.comments)
  }


  const onHandleInputName = (card: cardType) => {
    // setIsDraggable(false) // запрещаем перемещать карточку во время ввода текста
    showCardModal(true, card, modalType.saveInput)
  };


  const animatedLines = (isAnimated) => {
    dispatch(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {cards: initialElements, connection_lines: isAnimated.newConnectLines},
        ordinagramId: Number(activeOrdinagramId),
      }
    } as getCardsActionType))
  }


  const deleteLines = (cardId) => {
    cardId && dispatch({
      type: DISCONNECT_CONNECTION_LINE_SAGA,
      payload: {card_id: cardId, ordinagram_id: activeOrdinagramId}
    })
  }


  const onNodeDrag = (event, node) => {
    let animatedConnectLines = []
    let deleted = false
    const animated = Math.abs(nodeDragStart.x - node.position.x) > 950 ||
      Math.abs(nodeDragStart.y - node.position.y) > 950 || false
    const newConnectLines = connectLines?.map((line: connectLinesType) => {
      if (line.source === node.id || line.target === node.id) {
        return {...line, animated}
      }
      return {...line}
    })
    if (newConnectLines.length !== 0 && animated) {
      deleted = Math.abs(nodeDragStart.x - node.position.x) > 1150 ||
        Math.abs(nodeDragStart.y - node.position.y) > 1150 || false
      animated && (animatedConnectLines = newConnectLines?.filter((line: connectLinesType) => line.animated === true))
    }
    animatedConnectLines.length !== 0 && animated
      ? setIsAnimatedLine({animated, newConnectLines}) : setIsAnimatedLine({animated: false, newConnectLines})
    animatedConnectLines.length !== 0 && deleted ? setIdDeletedLine(node.id) : setIdDeletedLine(null)
  }


  const onNodeDragStart = (event, node) => {
    setNodeDragStart({
      x: node.position.x,
      y: node.position.y
    })
  }


  const onNodeDragStop = (event, node, ordinagramId) => {
    const activeCard = initialElements.find(item => item.id === node.id)
    setDataActiveCard(activeCard)
    const newConnectLines = connectLines?.map((line: connectLinesType) => {
      return {...line, animated: false}
    })
    dispatch(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {
          cards: initialElements,
          connection_lines: newConnectLines,
        },
        ordinagramId: Number(activeOrdinagramId)
      }
    }))
    dispatch({
      type: PATCH_CARD_SAGA,
      payload: {card_id: node.id, property: node.position, ordinagram_id: ordinagramId, type: patchCard.position}
    })
  }

  const previewStyles = (type, card) => {
    switch (type) {
      case 'window':
        return {
          height: previewPrint.A3 || previewPrint.A4 ? '100%' : '85vh',
          // border: "1px solid red",
        }
      case 'preview-frame':
        return {
          width: previewPrint.A3 ? 2660 : previewPrint.A4 ? 1900 : '100%',
          height: previewPrint.A3 ? 1733 : previewPrint.A4 ? 1280 : 'auto',
          border: (previewPrint.A3 || previewPrint.A4) && '1px solid #1D8BC5',
          margin: (previewPrint.A3 || previewPrint.A4) && '0 auto 30px auto',
        }
    }
  }

  const closePanels = () => {
    if (isLeftPanelOpen) {
      toggleLeftSidePanel()
    }
    if (isRightPanelOpen) {
      toggleRightSidePanel()
    }
  }

  return (
    <div id="print-div" style={previewStyles('preview-frame', {})}>
      {openModalDetail &&
      <DetailsCard
        setOpenModalDetail={setOpenModalDetail}
        showCardModal={showCardModal}
        open={openModalDetail}
        card={dataActiveCard}
      />}
      <ReactFlow
        id="flow"
        elements={elements}
        onLoad={onLoad}
        snapToGrid
        snapGrid={[10, 10]}
        defaultZoom={0.8}
        onPaneClick={closePanels}
        nodesDraggable={activeOrdm?.isDraggableCard}
        minZoom={0.1}
        maxZoom={5}
        zoomOnScroll={activeOrdm?.zoomOnScroll}
        onNodeDragStop={(event, node: Node) => onNodeDragStop(event, node, activeOrdinagramId)}
        onNodeDrag={(event, node) => onNodeDrag(event, node)}
        onNodeDragStart={(event, node) => onNodeDragStart(event, node)}
        // onEdgeUpdate={onEdgeUpdate}
        onConnect={onConnect}
        //@ts-ignore
        connectionLineType={activeOrdm?.connect_line}
        style={previewStyles('window', {})}
      >
        <div/>
        {!(previewPrint.A3 || previewPrint.A4) && <Controls/>}
      </ReactFlow>
    </div>
  )

};
