import { Button } from "../../../../../ui-kit";
import {useTranslation} from "react-i18next";

export type Props = {
  isUserCheckStarted: boolean;
  startChecking: any;
  finishChecking: any;
  totalCount: number;
  count: number;
  resetCount: () => void;
  isFiltered: any;
  title: string | undefined;
};

export const UserCheck = (props: Props) => {
  const {
    isUserCheckStarted,
    startChecking,
    finishChecking,
    totalCount,
    count,
    resetCount,
    isFiltered,
    title,
  } = props;

  const endOfChecking = () => {
    finishChecking();
    resetCount();
  };

  const [t] = useTranslation();
  const buttonDisplay = `${isFiltered && "display-none"}`;

  return (
    <div className="user-check">
      <p className="user-check__text">{title ? title : t('employees')}</p>
      <div className="counter">
        <span className="counter__on-duty">{count}</span>/
        <span className="counter__all">{totalCount}</span>
      </div>
      {isUserCheckStarted ? (
        <Button
          type="button"
          text={t('ready_btn')}
          className={`finish-count-button ${buttonDisplay}`}
          onClick={endOfChecking}
        />
      ) : (
        <Button
          type="button"
          text={t('begin')}
          className={`finish-count-button ${buttonDisplay}`}
          onClick={startChecking}
        />
      )}
    </div>
  );
};
