import React, { FC } from "react";
import styles from './../card.module.scss';
import {Handle} from "react-flow-renderer";
import {plugAvatar} from "../../images/index";
import {cardType} from "../../../../types/types";
import {useTranslation} from "react-i18next";

const printColor = {
  '#A4D0E7': '#0FA0CE',
  '#005B9F': '#005B9F',
  '#CE93D8': '#A247B2',
  '#836FA9': '#60409F',
  '#C5E1A5': '#929C01',
  '#519657': '#003D00',
  '#FFCDD2': '#DC0385',
  '#E57373': '#8F1616',
  '#FFECB3': '#E5C000',
  '#FFCC80': '#E65100',
  '#A1887F': '#4E342E',
  '#80CBC4': '#467672',
}

type ReturnLabelType = {
  card: cardType
}

const CardPreview: FC<ReturnLabelType> = ({
                                            card,
                                          }) => {

  const [t] = useTranslation();

  return (
    <>
      <div
        key={card?.id}
        style={{
          border: `4px solid ${printColor[`${card.color_card}`] || '#0FA0CE'}`,
          opacity: card.filterStatus.opacity,
        }}
        className={`${styles.card} ${styles.card_preview}`}

      >
        <div className={styles.item}>
          <img src={card.avatar_image ? card.avatar_image : plugAvatar} alt="avatar"
               className={`${styles.avatar} ${styles.avatar_preview}`}/>
          <div className="d-flex flex-d-col align-items-f-start ml-10" style={{flex: 1}}>
            <p className={styles.callSign} style={{
              margin: '5px 0px',
              color: printColor[`${card.color_card}`] || '#0FA0CE'
            }}>{card.call_sign}</p>
            {card?.full_name === "" ?
              <p className={styles.input} style={{color: '#C4CCD7'}}>{t('card_placeholder')}</p>
              : <p className={`${styles.input} ${styles.input_preview}`}>{card?.full_name}</p>}
            {card.status.status && !card.status.is_expired ?
              <p className={styles.status} style={{background: card.status.color_string}}>{card.status.status}</p>
              : <p className={styles.workingPosition}>{card.working_position}</p>
            }
          </div>
        </div>
      </div>
      <Handle
        type="target"
        //@ts-ignore
        position="top"
        id="a"
        style={{borderRadius: 0, bottom: 0, width: 22, height: 10, background: 'transparent'}}
      />
      <Handle
        type="source"
        //@ts-ignore
        position="bottom"
        id="c"
        style={{borderRadius: 0, bottom: 0, width: 22, left: '50%', height: 10, background: 'transparent'}}
      />
    </>
  )
}

export default CardPreview;
