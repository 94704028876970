import React, {FC} from "react";
import clsx from "clsx";
import styles from "../menu-card.module.scss";
import {useTranslation} from "react-i18next";

type DeleteCardType = {
  onDelete: () => void
}

const DeleteCard: FC<DeleteCardType> = ({onDelete}) => {
  const [t] = useTranslation();
  return <button
    onClick={onDelete}
    className={clsx(styles.text)}
  >{t('delete_btn')}</button>
}
export default DeleteCard;
