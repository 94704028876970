import React from "react";

export function Google() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.7083 15.0623H31.5V15H18V21H26.4773C25.2405 24.4928 21.9173 27 18 27C13.0298 27 9.00003 22.9703 9.00003 18C9.00003 13.0298 13.0298 9.00003 18 9.00003C20.2943 9.00003 22.3815 9.86553 23.9708 11.2793L28.2135 7.03653C25.5345 4.53978 21.951 3.00003 18 3.00003C9.71628 3.00003 3.00003 9.71628 3.00003 18C3.00003 26.2838 9.71628 33 18 33C26.2838 33 33 26.2838 33 18C33 16.9943 32.8965 16.0125 32.7083 15.0623Z"
        fill="#FFC107"
      />
      <path
        d="M4.72957 11.0183L9.65782 14.6325C10.9913 11.331 14.2208 9.00003 18.0001 9.00003C20.2943 9.00003 22.3816 9.86553 23.9708 11.2793L28.2136 7.03653C25.5346 4.53978 21.9511 3.00003 18.0001 3.00003C12.2386 3.00003 7.24207 6.25278 4.72957 11.0183Z"
        fill="#FF3D00"
      />
      <path
        d="M18 33.0001C21.8745 33.0001 25.395 31.5173 28.0567 29.1061L23.4142 25.1776C21.8576 26.3614 19.9556 27.0016 18 27.0001C14.0985 27.0001 10.7857 24.5123 9.53774 21.0406L4.64624 24.8093C7.12874 29.6671 12.1702 33.0001 18 33.0001Z"
        fill="#4CAF50"
      />
      <path
        d="M32.7083 15.0622H31.5V15H18V21H26.4773C25.8857 22.6623 24.82 24.1149 23.412 25.1782L23.4142 25.1767L28.0568 29.1052C27.7283 29.4037 33 25.5 33 18C33 16.9942 32.8965 16.0125 32.7083 15.0622Z"
        fill="#1976D2"
      />
    </svg>
  );
}
