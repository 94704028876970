import { Link } from "react-router-dom";

export const CustomLink = (props) => {
  const { text, to, className, Icon, textClassName } = props;
  return (
    <Link to={to} className={className}>
      {Icon && <Icon />}
      <p className={textClassName}>{text}</p>
    </Link>
  );
};