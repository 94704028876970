import {generatePostFieldsType} from "../../actions/diagramActions/generatePostFields";
import produce from "immer";
import {initStateType} from "../../reducer";
import {WritableDraft} from "immer/dist/types/types-external";
import {FieldsType} from "../../../../../types/detailsCardTypes";


export const generatePostFieldsReducer = (state: initStateType, action: generatePostFieldsType) =>
  produce(state, (draft: WritableDraft<initStateType>) => {
    const fields: FieldsType[] = action.payload.fields.map((item: FieldsType): FieldsType => {
      return {
        icon: item.icon || null,
        id: item.id || null,
        order_id: item.order_id,
        title: item.title,
        type: item.type,
        value: item.value,
      }
    })
    draft.fieldsDetailsCard = fields
  })