import React, {FC} from "react";
import styles from "../details-card.module.scss";
import {changeType} from "../constants";
import {useTranslation} from "react-i18next";

type FullNameType = {
  full_name: string
  objectPutFormation: (e, changeType: string) => void
}

const FullNameInput: FC<FullNameType> = ({
                                           full_name,
                                           objectPutFormation
                                         }) => {
    const [t] = useTranslation();

  return (
    <input
      onChange={e => objectPutFormation(e, changeType.full_name)}
      className={styles.input}
      defaultValue={full_name}
      placeholder={t('card_placeholder')}/>
  )
}

export default FullNameInput
