export type Props = {
  text: string
  type: "button" | "submit" | "reset"
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className: string
  disabled?: boolean
}

export const Button = (props: Props) => {
    const { text, type, onClick, className, disabled } = props;
    return (
      <button type={type} onClick={onClick} className={className} disabled={disabled}>
        {text}
      </button>
    );
  };