// import * as t from '../../actionTypes'
import {GET_ORDINAGRAMS_ACTION} from "../../actionTypes";
import {ordinagramType} from "../../../../../types/types";

export type getOrdinagramsActiontype = {
  type: typeof GET_ORDINAGRAMS_ACTION,
  payload: ordinagramType[],
}

export const getOrdinagrams = (data) => {
  return {
    type: data.type,
    payload: data.payload
  }
}

