import React, {useState} from "react";
import {connector} from "./container";
import {ControlPanel} from "../../components/control-panel/control-panel";
import Main from "../../components/diagrams/Main";
import SidePanelLeft from "../../components/side-panels/side-panel-left/side-panel-left";
import SidePanelRight from "../../components/side-panels/side-panel-right/side-panel-right";
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { api } from '../../api/restClient'

const HomePage = (props) => {

  const {
    home,
    user,
    cards,
    toggleLeftSidePanel,
    toggleRightSidePanel,
    startChecking,
    finishChecking,
  } = props;

    const [shareOpen, setShareOpen] = useState(false)
    const [fileForShare, setFileShare] = useState(null)

    const getPDF = (actionType, fileName) => {
        const HTML_Width = document.getElementById('print-div').offsetWidth;
        const HTML_Height = document.getElementById('print-div').offsetHeight;

        const PDF_WIDTH = 794
        const PDF_HEIGHT = 1123

        const image_percent_width = PDF_WIDTH / HTML_Width
        const image_percent_height = PDF_HEIGHT /HTML_Width

        const canvas_image_width = HTML_Width * image_percent_width + 200;
        const canvas_image_height = HTML_Height * image_percent_height;
        const image = document.getElementById('print-div');
        const pdf = new jsPDF('l', 'pt',  'a4');
        const { REACT_APP_API_PROD } = process.env


        if (pdf) {
            domtoimage.toPng(image)
                .then(imgData => {
                    pdf.addImage(imgData, 'JPG', -100, 15, canvas_image_width, canvas_image_height);
                    if(actionType === 'download') {
                        savePdf(pdf, fileName)
                    } else {
                        const blobFile = pdf.output('blob')
                        const formData = new FormData();
                        formData.append('file', blobFile);
                        api.sendFile(`v1/upload-file/`, formData).then((res) => {
                            // @ts-ignore
                            const filePath = `${REACT_APP_API_PROD}${res?.data?.url}`
                            setFileShare(filePath)
                        })
                    }

                });
        }
    };


    const savePdf = (file, fileName) => {
        file.save(fileName);
    }

    const handleShare = () => {
        getPDF('share', 'test')
        setShareOpen(shareOpen => !shareOpen)
    }

    const closeShare = () => {
        setFileShare(null)
        setShareOpen(false)
    }

  return (
    <div id="home-page" style={{position: 'relative'}}>
      <ControlPanel
        handleShare={handleShare}
        shareOpen={shareOpen}
        setShareOpen={setShareOpen}
        downloadDocument={getPDF}
        toggleLeftSidePanel={toggleLeftSidePanel}
        toggleRightSidePanel={toggleRightSidePanel}
        isLeftPanelOpen={home.leftSidePanelActive}
        isRightPanelOpen={home.rightSidePanelActive}
        fileForShare={fileForShare}
      />
        <div onClick={closeShare}>
            <Main
                toggleLeftSidePanel={toggleLeftSidePanel}
                toggleRightSidePanel={toggleRightSidePanel}
                isLeftPanelOpen={home.leftSidePanelActive}
                isRightPanelOpen={home.rightSidePanelActive}
            />
        </div>
      <SidePanelLeft isOpen={home.leftSidePanelActive} user={user}/>
      <SidePanelRight
        isOpen={home.rightSidePanelActive}
        isUserCheckStarted={home.isUserCheckStarted}
        startChecking={startChecking}
        finishChecking={finishChecking}
        cards={cards}
        toggleRightSidePanel={toggleRightSidePanel}
      />
    </div>
  );
};

// @ts-ignore
export default connector(HomePage)
