export const colors = [
  {'#A4D0E7': 'blue_color'},
  {'#005B9F': 'dark_blue_color'},
  {'#CE93D8': 'purple_color'},
  {'#836FA9': 'Violet_color'},
  {'#C5E1A5': 'light_green_color'},
  {'#519657': 'green_color'},
  {'#FFCDD2': 'pink_color'},
  {'#E57373': 'red_color'},
  {'#FFECB3': 'yellow_color'},
  {'#FFCC80': 'orange_color'},
  {'#A1887F': 'brown_color'},
  {'#80CBC4': 'turquoise_color'},
]

export const statuses = [
  {'#E57373': 'is_ill'},
  {'#80CBC4': 'vacation'},
  {'#FFCC80': 'business_trip'},
]
