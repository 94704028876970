import React from "react";

export const Email = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3334 5.00009C18.3334 4.08342 17.5834 3.33342 16.6667 3.33342H3.33337C2.4167 3.33342 1.6667 4.08342 1.6667 5.00009M18.3334 5.00009V15.0001C18.3334 15.9168 17.5834 16.6668 16.6667 16.6668H3.33337C2.4167 16.6668 1.6667 15.9168 1.6667 15.0001V5.00009M18.3334 5.00009L10 10.8334L1.6667 5.00009"
        stroke="black"
        strokeOpacity="0.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
