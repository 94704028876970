import React, {FC} from "react";
import Modal from "@material-ui/core/Modal";
import styles from './confirm.module.scss'
import {useDispatch} from "react-redux";
import {DELETE_CARD_SAGA, DELETE_ORDINAGRAM_SAGA} from "../../pages/home/redux-sagas/actionTypes";
import {cardType} from "../../types/types";
import {useTranslation} from "react-i18next";

type ConfirmType = {
  setOpenModalConfirm: (boolean) => void
  setOpenModalDetail?: (boolean) => void
  open: boolean,
  type: string,
  idRemovableOrdm?: number,
  card?: cardType
}

const Confirm: FC<ConfirmType> = ({
                                    setOpenModalConfirm,
                                    setOpenModalDetail,
                                    open,
                                    type,
                                    idRemovableOrdm,
                                    card
                                  }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation();

  const deleteType = {
    delete_ordm: t('deleting_an_ordinagram'),
    delete_card: t('deleting_card')
  }
  const deleteData = () => {
    switch (type) {
      case deleteType.delete_ordm:
        return deleteOrdm(idRemovableOrdm)
      case deleteType.delete_card:
        return deleteCard(card)
    }
  }

  const deleteOrdm = (idOrdm) => {
    dispatch({type: DELETE_ORDINAGRAM_SAGA, payload: {id: idOrdm}})
    setOpenModalConfirm(false);
  }

  const deleteCard = (card) => {
    dispatch({type: DELETE_CARD_SAGA, payload: {card_id: card.id, ordinagram_id: card.ordinagram_id}})
    setOpenModalConfirm(false);
    setOpenModalDetail && setOpenModalDetail(false);
  }

  const handleClose = () => {
    setOpenModalConfirm(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={true}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <h4 className={styles.description}>{type}</h4>
          <p className={styles.text}>{t('delete_question')}</p>
          <div className="d-flex justify-content-evenly mt-15" style={{width: '100%'}}>
            <button onClick={handleClose} className={`${styles.btn} ${styles.btn_cancel}`}>{t('cancel_btn')}</button>
            <button onClick={deleteData} className={styles.btn}>{t('delete_btn')}</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Confirm;
