import React from 'react'
import classnames from 'classnames'
import styles from './dropdown-item.module.scss'

export const DropdownItem = (props) => {
  const { item, onSelectItem } = props

  return (
    <li onClick={onSelectItem} className={classnames('dropDownItem', styles.dropdownItem)}>
      {item}
    </li>
  )
}
