import React, {FC, useEffect, useState} from "react";
import styles from './menu-card.module.scss';
import clsx from "clsx";
import {cardType, ordinagramType} from "../../types/types";
import ColorPanel from "./color-panel/color-panel";
import StatusPanel from "./status-panel/status-panel";
import {useDispatch, useSelector} from "react-redux";
import {appGlobalStore} from "../../store/root-reducer";
import {getOrdinagrams} from "../../pages/home/redux-sagas/actions/diagramActions/getOrdinagrams";
import {GET_ORDINAGRAMS_ACTION} from "../../pages/home/redux-sagas/actionTypes";
import DeleteCard from "./delete-card/delete-card";
import Confirm from "../confirm/confirm";
import './style.scss'
import {useTranslation} from "react-i18next";

type MenuCardType = {
  card: cardType
  setOpenModalDetail?: (boolean) => void
}

const menuType = {
  default: 'default',
  color: 'color',
  status: 'status',
}

const MenuCard: FC<MenuCardType> = ({
                                      card,
                                      setOpenModalDetail,
                                    }) => {
  const dispatch = useDispatch()
  const ordinagrams = useSelector(({homePage}: appGlobalStore): ordinagramType[] => homePage.ordinagrams)
  const [showPanel, setShowPanel] = useState<string>(menuType.default)
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [t] = useTranslation();

  const deleteType = {
    delete_ordm: t('deleting_an_ordinagram'),
    delete_card: t('deleting_card')
  }

  const onDelete = () => {
    setOpenModalConfirm(true)
    const newOrdinagrams: ordinagramType[] = ordinagrams.map((ordm: ordinagramType): ordinagramType => {
      if (ordm.id === card.ordinagram_id) {
        return {...ordm, zoomOnScroll: true, isDraggableCard: true}
      }
      return {...ordm}
    })
    dispatch(getOrdinagrams({
      type: GET_ORDINAGRAMS_ACTION,
      payload: newOrdinagrams
    }))
  }

  return (
    <div>
      {showPanel === menuType.default && (
        <div className={styles.menu} style={{padding: 8}}>
          <button onClick={() => setShowPanel(menuType.status)} className={clsx(styles.text)}>{t('add_status')}</button>
          <button onClick={() => setShowPanel(menuType.color)}
                  className={clsx(styles.text)}>{t('select_color')}
          </button>
          <DeleteCard onDelete={onDelete}/>
        </div>
      )}
      {showPanel === menuType.status && <StatusPanel card={card}/>}
      {showPanel === menuType.color && <ColorPanel card={card}/>}
      {openModalConfirm && <Confirm setOpenModalConfirm={setOpenModalConfirm}
                                    setOpenModalDetail={setOpenModalDetail}
                                    open={openModalConfirm}
                                    type={deleteType.delete_card}
                                    card={card}/>}
    </div>

  )

}
export default MenuCard
