import produce from "immer";
import {initStateType} from "../../reducer";
import {getDetailsCardType} from "../../actions/diagramActions/getDetailsCard";
import {detailsCardType} from "../../../../../types/detailsCardTypes";

export const getDetailsCardReducer = (state: initStateType, action: getDetailsCardType) =>
  produce(state, (draft => {
    const details: detailsCardType = action.payload.details
    const detailsCard: detailsCardType | {} = Object.keys(details).length !== 0 ? {
      avatar_image: details.avatar_image,
      call_sign: details.call_sign,
      comments: details.comments,
      documents: details.documents,
      fields: details.fields,
      full_name: details.full_name,
      id: details.id,
      original_image: details.original_image,
      position: details.position,
      status: details.status,
      working_position: details.working_position,
      ordinagram_id: action.payload.ordinagram_id,
      card_id: action.payload.card_id,
      color_card: details.color_card || '#A4D0E7',
    } : {}

    draft.detailsCard = detailsCard
  }))