import {all, put, call, takeEvery, select} from "redux-saga/effects";
import {
  GET_DETAILS_CARD_ACTION,
  PATCH_DOCUMENT_SAGA,
  POST_DOCUMENT_SAGA
} from "../../actionTypes";
import {getDocumentsType} from "../../actions/diagramActions/getDocuments";
import {api} from "../../../../../api/restClient";
import {getDetails, getDetailsCardType} from "../../actions/diagramActions/getDetailsCard";
import {detailsCardType} from "../../../../../types/detailsCardTypes";


const postApiDocument = (postObj) => {
  const {cardId, ordmId, params} = postObj
  return api.post(`v1/organigram/${ordmId}/cards/${cardId}/files/`, params)
    .catch((err) => console.log(err))
}

const getApiDetailsCard = (getObj) => {
  const {cardId, ordmId} = getObj
  return api.get(`v1/organigram/${ordmId}/cards/${cardId}/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

const patchApiDocument = (patchObj) => {
  const {cardId, ordmId, params} = patchObj
  return api.patch(`v1/organigram/${ordmId}/cards/${cardId}/files/${params.id}/`, params)
    .catch((err) => console.log(err))
}

function* getDet(getObj) {
  const details: detailsCardType = yield call(getApiDetailsCard, getObj)
  const getDetailsCardAction: getDetailsCardType = {
    type: GET_DETAILS_CARD_ACTION,
    payload: {
      card_id: getObj.cardId,
      ordinagram_id: getObj.ordmId,
      details: details,
      requestType: ""
    }
  }
  yield put(getDetails(getDetailsCardAction))
}

function* patchDocumentAsync(action) {
  const patchObj = {
    cardId: action.payload.card_id,
    ordmId: action.payload.ordinagram_id,
    params: action.payload.document
  }
  const getObj = {cardId: action.payload.card_id, ordmId: action.payload.ordinagram_id}
  yield call(patchApiDocument, patchObj)
  yield call(getDet, getObj)
}

function* postDocumentAsync(action: getDocumentsType) {
  const postObj = {
    cardId: action.payload.card_id,
    ordmId: action.payload.ordinagram_id,
    params: action.payload.document,
  }
  const getObj = {cardId: action.payload.card_id, ordmId: action.payload.ordinagram_id}
  yield call(postApiDocument, postObj)
  yield call(getDet, getObj)
}

export function* postDocumentSagas() {
  yield takeEvery(POST_DOCUMENT_SAGA, postDocumentAsync)
  yield takeEvery(PATCH_DOCUMENT_SAGA, patchDocumentAsync)
}