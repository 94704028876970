import React, {FC} from "react";
import styles from "../details-card.module.scss";
import {changeType} from "../constants";
import {useTranslation} from "react-i18next";

type WorkingPositionType = {
  working_position: string
  objectPutFormation: (e, changeType: string) => void
}

const WorkingPosition: FC<WorkingPositionType> = ({
                                                    working_position,
                                                    objectPutFormation,
                                                  }) => {
  const [t] = useTranslation();

  return (
    <input
      onChange={e => objectPutFormation(e, changeType.working_position)}
      className={styles.inputWorkingPosition}
      defaultValue={working_position}
      placeholder={t('card_position_placeholder')}/>
  )
}

export default WorkingPosition;
