import createReducer from 'store/createReducer'

import * as reducers from './reducers'
import * as t from './actionTypes'

const initState = {
    loggedIn: false,
    secretLink: '',
    email: '',
    googleLink: '',
    loading: false,
    isError: false,
    errorText: '',
}

const authorizationReducer = createReducer(initState, {
  [t.LOGGED_IN]: reducers.loggedInReducer,
  [t.LOGGED_OUT]: reducers.loggedOutReducer,
  [t.SET_EMAIL_FOR_RESTORE_PASSWORD]: reducers.setEmailForRestorePasswordReducer,
  [t.SET_SECRET_LINK]: reducers.setSecretLinkReducer,
  [t.SET_GOOGLE_LINK]: reducers.setGoogleLinkReducer,
  [t.SET_LOADING_TRUE]: reducers.setLoadingTrueReducer,
  [t.SET_LOADING_FALSE]: reducers.setLoadingFalseReducer,
  [t.SHOW_ERROR]: reducers.showErrorReducer,
  [t.HIDE_ERROR]: reducers.hideErrorReducer,
})

export default authorizationReducer