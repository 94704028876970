import { produce } from 'immer'

  export const showErrorReducer = (state, action) =>
  produce(state, (draft) => {
    draft.isError = true
    draft.errorText = action.payload
  })

  export const hideErrorReducer = (state) =>
  produce(state, (draft) => {
    draft.isError = false
    draft.errorText = ''
  })