export const changeType = {
  full_name: 'full_name',
  working_position: '',
  call_sign: 'call_sign',
  comments: 'comments',
}

export const valueInputsArray = [
  {
    id: 0,
    icon: "https://my-planer.com/media/icons/text.svg",
    title: "Текст",
    type: 'text',
  },
  {
    id: 1,
    icon: "https://my-planer.com/media/icons/date.svg",
    title: "Дата",
    type: 'date',
  },
  {
    id: 2,
    icon: "https://my-planer.com/media/icons/phone.svg",
    title: "Телефон",
    type: "phone",
  },
  {
    id: 3,
    icon: "https://my-planer.com/media/icons/email.svg",
    title: "Почта",
    type: "email",
  },
  {
    id: 4,
    icon: "https://my-planer.com/media/icons/address.svg",
    title: "Адрес",
    type: "address",
  }
]
