import React, {FC, FormEvent, useState} from "react";
import styles from './card.module.scss';
import {TooltipBtn} from "../ui/tooltip/material-ui-styles";
import Button from "@material-ui/core/Button";
import {Handle} from "react-flow-renderer";
import {commentSvg, fileSvg, labelRedSvg, plugAvatar, unionSvg} from "../images/index";
import MenuCard from "../../../modals/menu-card/menu-card";
import {cardType} from "../../../types/types";
import {Save, saveType} from "../../../modals/save/save";
import {modalType} from "../Main";
import Comments from "../../../modals/data-card/components/comments/comments";
import DocumentsModal from "../../../modals/documents-modal/documents-modal";
import {useTranslation} from "react-i18next";

type ReturnLabelType = {
  card: cardType
  elements: any
  onSubmitName: (e, card: cardType, value: string) => void
  onSubmitComment: (card: cardType, value: string) => void
  initialElements: () => void
  onHandleInputName: (card: cardType) => void
  onAddLeft: (event, card: cardType) => void
  onAddRight: (event, card: cardType) => void
  onAddBottom: (event, card: cardType) => void
  showCardModal: (open, card: cardType, type) => void
  handleDoubleClickCard: (card: cardType) => void
}

const Card: FC<ReturnLabelType> = ({
                                     card,
                                     onSubmitName,
                                     onHandleInputName,
                                     onAddBottom,
                                     onAddLeft,
                                     onAddRight,
                                     showCardModal,
                                     handleDoubleClickCard,
                                   }) => {

  const [t] = useTranslation();
  const [valueInputName, setValueInputName] = useState('')

  return (
    <>
      <div
        onDoubleClick={() => handleDoubleClickCard(card)}
        key={card?.id}
        className={styles.wrapper}
        style={{
          width: '100%',
          opacity: card.filterStatus.opacity
        }}
      >
        <div className={styles.statusColor} style={{background: card?.color_card || '#A4D0E7'}}/>
        <div className={styles.item}>
          <img src={card.avatar_image || plugAvatar} alt="avatar"
               className={styles.avatar}/>
          <div className="d-flex flex-d-col align-items-f-start ml-10">
            {
              (card?.full_name === '') ?
              <form id="name1" onSubmit={(e: FormEvent<HTMLFormElement>) => onSubmitName(e, card, valueInputName)}>
                <input
                  type="text"
                  className={styles.input}
                  onChange={(e) => setValueInputName(e.target.value)}
                  onMouseDown={() => onHandleInputName(card)}
                  placeholder={t('card_placeholder')}
                  defaultValue={card?.full_name === '' ? '' : card.full_name}
                />
              </form>
              : <p className={styles.input}>{card?.full_name}</p>
            }
            <p className={styles.workingPosition}>{card?.working_position}</p>
            {card.status.status && !card.status.is_expired &&
            <p className={styles.status} style={{background: card.status.color_string}}>{t(`${card.status.status}`)}</p>}
          </div>
        </div>

        <div className={styles.item}>
          <TooltipBtn title={t('comments_hover_text')} enterDelay={400}>
            <Button onClick={() => card.openComments ?
              showCardModal(false, card, modalType.comments) : showCardModal(true, card, modalType.comments)}
                    style={{minWidth: 20, background: 'transparent', padding: 0}}>
              <img className="card__svg" src={commentSvg} alt="comment"/>
            </Button>
          </TooltipBtn>
          <TooltipBtn title={t('documents')} enterDelay={400}>
            <Button onClick={() => card.openDocument ?
              showCardModal(false, card, modalType.document) : showCardModal(true, card, modalType.document)}
                    style={{minWidth: 20, background: 'transparent', padding: 0, marginLeft: 4, outline: 'none'}}>
              <img className="card__svg" src={fileSvg} alt="file"/>
              {card.document_expired && <img src={labelRedSvg} alt="red"
                                             style={{
                                               position: 'absolute',
                                               top: 5,
                                               right: 5,
                                             }}/>
              }
            </Button>
          </TooltipBtn>
          <p className={styles.callSign} style={{margin: '0px 0px 0px auto'}}>{card.call_sign}</p>
        </div>
        {card.inputFocus && <Save type={saveType.card}
                                  card={card}
                                  className="modal-card"
                                  onSubmitName={onSubmitName}
                                  valueInputName={valueInputName}
                                  showCardModal={showCardModal}
                                  text={t('save_btn')}
        />}
      </div>

      {card.openComments &&
      <Comments openComments={card?.openComments} comments={card.comments} className="card-comment"/>}
      {card.openDocument &&
      <DocumentsModal card={card} className="card-document"/>}

      <button
        onClick={() => card.openMenuCard ?
          showCardModal(false, card, modalType.menuCard) : showCardModal(true, card, modalType.menuCard)}
        onTouchStart={() => card.openMenuCard ?
          showCardModal(false, card, modalType.menuCard) : showCardModal(true, card, modalType.menuCard)}
        onKeyUp={(e) => {
          card.openMenuCard && e.key === "Escape" && showCardModal(false, card, modalType.menuCard)
        }}
        className={`${styles.menu} ${styles.menu_right}`}>
        <img src={unionSvg} alt="menu"/>
      </button>
      <button onClick={(event) => onAddLeft(event, card)}
              onTouchStart={(event) => onAddLeft(event, card)}
              className={`${styles.add} ${styles.add_left}`}>+
      </button>
      <button onClick={(event) => onAddBottom(event, card)}
              onTouchStart={(event) => onAddBottom(event, card)}
              className={`${styles.add} ${styles.add_bottom}`}>+
      </button>
      <button onClick={(event) => onAddRight(event, card)}
              onTouchStart={(event) => onAddRight(event, card)}
              className={`${styles.add} ${styles.add_right}`}>+
      </button>

      {card.openMenuCard && <MenuCard card={card}/>}

      <Handle
        type="target"
        //@ts-ignore
        position="top"
        id="a"
        style={{
          borderTopRightRadius: 1,
          borderTopLeftRadius: 1,
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          bottom: 0,
          width: 28,
          height: 10,
          background: 'transparent'
        }}
      />
      <Handle
        type="source"
        //@ts-ignore
        position="bottom"
        id="c"
        style={{
          borderTopRightRadius: 3,
          borderTopLeftRadius: 3,
          borderBottomLeftRadius: 1,
          borderBottomRightRadius: 1,
          bottom: 0,
          width: 28,
          left: '50%',
          height: 10,
          background: 'transparent'
        }}
      />
    </>
  )
}

export default Card;
