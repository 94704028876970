import {api} from "../../../../../api/restClient";
import {call, put, takeEvery, select} from "@redux-saga/core/effects";
import {GET_CARDS_ACTION, GET_CARDS_SAGA} from "../../actionTypes";
import {getCardsAction} from "../../actions/diagramActions/getCards";

const getApiCards = (id) => {
  return api.get(`v1/organigram/${id}/cards/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

function* getCardsAsync(action) {
  try {
    const {cards, connection_lines} = yield call(getApiCards, action.payload)
    yield put(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {
          cards,
          connection_lines,
        }, ordinagramId: action.payload
      }
    }))
  } catch (err) {
    alert('Медленная скорость интернета для загрузки карточек ординаграммы')
  }
}

export function* getCardsSagas() {
  yield takeEvery(GET_CARDS_SAGA, getCardsAsync)
}