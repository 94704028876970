import { useHistory } from "react-router-dom";
import { Form, InputPassword } from "./parts";
import { Button } from "../../ui-kit";
import { useFormWithValidation } from "../../utils/validation";
import { setNewPassword } from "../../api/authApi";
import { connector } from "./container";
import React from "react";

const NewPassword = (props) => {
  const { authorization } = props;
  const history = useHistory();

  const {
    values,
    handleChange,
    errors,
    isValid,
    resetForm,
  } = useFormWithValidation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setNewPassword(values, authorization.secretLink).then((res) => {
      history.push("/login");
      resetForm();
    });
  };

  return (
    <Form
      linkTo="/login"
      linkText="Вернуться на страницу входа"
      title="Восстановление пароля"
      onSubmit={handleSubmit}
    >
      <InputPassword onChange={handleChange} errorText={errors.password} />
      <Button
        type="submit"
        text="Продолжить"
        className="authForm__button"
        disabled={!isValid}
      />
    </Form>
  );
};

export default connector(NewPassword);
