import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as actions from "./redux-sagas/actions";
import * as selectors from "./redux-sagas/selectors";
import { setUserEmail } from '../home/redux-sagas/actions'

const mapStateToProps = createStructuredSelector({
  authorization: selectors.authPageStateSelector,
});

const mapDispatchToProps = {
  loggedIn: actions.loggedIn,
  setEmail: actions.setEmailForRestorePassword,
  setSecretLink: actions.setSecretLink,
  setGoogleLink: actions.setGoogleLink,
  setLoadingTrue: actions.setLoadingTrue,
  setLoadingFalse: actions.setLoadingFalse,
  showError: actions.showError,
  hideError: actions.hideError,
  setUserEmail,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);