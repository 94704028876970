import { produce } from "immer";

export const setFullUserReducer = (state, action) =>
  produce(state, (draft) => {
    const { email, name, avatar } = action.payload;

    if (email !== "") {
      draft.user.email = email;
    }
    if (name !== "") {
      draft.user.name = name;
    }
    if (avatar !== "") {
      draft.user.avatar = `https://my-planer.com${avatar}`;
    }
  });
