import React, {FC, FormEvent} from "react";
import './save.scss';

import clsx from "clsx";
import {closeSaveSvg} from "./images";
import {cardType} from "../../types/types";
import {modalType} from "../../components/diagrams/Main";
import {changeType} from "../data-card/constants";

export const saveType = {
  card: 'card',
  createOrdinagram: 'create-ordinagram',
  settingsColorCard: 'settings-color-card',
  settingsStatusCard: 'settings-status-card',
  comments: 'comments',
  personalData: 'personalData',
}

type SaveType = {
  type: string,
  text: string,
  card?: cardType,
  valueInputName?: string
  valueComment?: string
  className?: string,
  selectedColor?: string,
  selectedStatus?: string
  oldValueComments?: string,
  disabled?: boolean
  createOrdm?: () => void
  handleClose?: () => void,
  newOrdm?: any,
  setCloseColorPanel?: (close: boolean) => void,
  setTextareaFocus?: (close: boolean) => void,
  setRedactor?: (close: boolean) => void,
  closeRedactor?: () => void
  onSubmitName?: (e, card: cardType, value) => void
  onSubmitFields?: (e) => void
  objectPatchFormation?: (e, changeType: string) => void
  // onSubmitComment?: (card: cardType, value: string) => void
  onSubmitColorStatus?: (e, card: cardType, value) => void
  onSubmitStatus?: (e, card: cardType, clickClose: boolean) => void
  showCardModal?: (input, card: cardType, type) => void
}


export const Save: FC<SaveType> = ({
                                     type,
                                     card,
                                     text,
                                     valueInputName,
                                     valueComment,
                                     oldValueComments,
                                     className,
                                     disabled,
                                     createOrdm,
                                     handleClose,
                                     onSubmitName,
                                     newOrdm,
                                     onSubmitFields,
                                     // onSubmitComment,
                                     setTextareaFocus,
                                     setRedactor,
                                     objectPatchFormation,
                                     selectedColor,
                                     selectedStatus,
                                     closeRedactor,
                                     onSubmitColorStatus,
                                     onSubmitStatus,
                                     setCloseColorPanel,
                                     showCardModal,
                                   }) => {

  switch (type) {
    case saveType.comments :
      return (
        <div className="d-flex align-items-center mt-15">
          <button type="submit"
                  onClick={() => {
                    objectPatchFormation(valueComment, changeType.comments)
                    setTextareaFocus(false)
                  }}
                  className="save-btn save-btn_textarea">{text}</button>
          <img
            onClick={() => {
              setTextareaFocus(false)
              objectPatchFormation(oldValueComments, changeType.comments)
            }}
            className="ml-15 cup" src={closeSaveSvg} alt="close"/>
        </div>
      )
    case saveType.card :
      return (
        <div className={clsx(className, "modal-card d-flex align-items-center mt-25")}>
          <form onSubmit={(e) => onSubmitName(e, card, valueInputName)}>
            <button
              className="save-btn save-btn_card mt-5">{text}</button>
          </form>
          <img
            onClick={(e) => {
              showCardModal(false, card, modalType.saveInput);
              onSubmitName(e, card, '')
            }}
            className="ml-10 cup" src={closeSaveSvg} alt="close"/>
        </div>
      )
    case saveType.createOrdinagram :
      return (
        <div className={clsx(className, "d-flex align-items-center mt-25")}>
          <button onClick={createOrdm}
                  className="save-btn save-btn_card"
                  style={{backgroundColor: newOrdm.title.length > 0 ? '#53A9D7' : '#878f93'}}
          >{text}</button>
          <img onClick={handleClose} className="ml-10 cup" src={closeSaveSvg} alt="close"/>
        </div>
      )
    case saveType.settingsColorCard:
      return (
        <div className={clsx(className, "d-flex align-items-center mt-25")}>
          <form
            onSubmit={(e) =>
              selectedColor ? onSubmitColorStatus(e, card, selectedColor) : onSubmitColorStatus(e, card, '')}
          >
            <button
              className="save-btn save-btn_card">{text}</button>
          </form>
          <img onClick={(e) => {
            setCloseColorPanel(true)
            onSubmitColorStatus(e, card, '')
          }}
               className="ml-10 cup" src={closeSaveSvg} alt="close"/>
        </div>
      )
    case saveType.settingsStatusCard:
      return (
        <div className={clsx(className, "d-flex align-items-center mt-25")}>
          <form
            onSubmit={(e) =>
              selectedStatus ? onSubmitStatus(e, card, false) : onSubmitStatus(e, card, true)}
          >
            <button
              className="save-btn save-btn_card"
              // disabled={true}
              disabled={disabled}
              style={{background: disabled && '#878f93'}}
            >{text}</button>
          </form>
          <img onClick={(e) => {
            onSubmitStatus(e, card, true)
          }}
               className="ml-10 cup" src={closeSaveSvg} alt="close"/>
        </div>
      )
    case saveType.personalData:
      return (
        <div className={clsx(className, "d-flex align-items-center mt-25")}>
          <form
            onSubmit={onSubmitFields}
          >
            <button
              className="save-btn save-btn_card">{text}</button>
          </form>
          <img onClick={closeRedactor}
               className="ml-10 cup" src={closeSaveSvg} alt="close"/>
        </div>
      )
  }
}