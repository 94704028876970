import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";


i18n.use(LanguageDetector).init({
    lng: "en",
    // we init with resources
    // \u000A - перенос строки
    resources: {
        en: {
            translations: {
                print_btn: 'Print',
                cancel_btn: 'Cancel',
                сreate_btn: 'Create',
                delete_btn: 'Delete',
                save_btn: 'Save',
                edit_btn: 'Edit',
                ready_btn: 'Ready',
                add_btn: 'Add',
                welcome_text: 'Welcome',
                organigram: 'Organigram',
                choose_an_organigram: 'Choose an organigram',
                create_new: 'Create new',
                organization_name: 'Name of the organization',
                select_type_of_organigram: 'Select the type of organigram',
                select_connecting_lines: 'Select connecting lines',
                roll_call: 'Roll call',
                by_surname: 'By surname',
                by_callsign: 'By callsign',
                by_department: 'By department',
                no_name: 'No name',
                without_caller: 'without caller',
                without_department: 'Without department',
                employees: 'Employees: ',
                begin: 'Start',
                no_workers: 'No workers',
                without_filter: 'Without a filter',
                without_status: 'Without status',
                select_status: 'Select status',
                is_ill: 'Is ill',
                vacation: 'Vacation',
                business_trip: 'Business trip',
                deleting_an_ordinagram: 'Deleting an ordinagram',
                deleting_card: 'Deleting a card',
                delete_question: 'Are you sure ?',
                show_cards: 'Show cards',
                select_end_date: 'Select completion date',
                delete_status: 'Delete status',
                add_status: 'Add status',
                select_color: 'Select color',
                card_placeholder: 'Name and surname',
                documents: 'Documents',
                comments_hover_text: 'Comments',
                no_documents_placeholder: 'No documents',
                card_callsign: 'Callsign',
                card_position_placeholder: 'Position',
                empty_input_placeholder: 'Empty',
                department: 'Department',
                date_of_birth: 'Date of birth',
                phone: 'Phone number',
                email: 'Email',
                notes: 'Notes',
                blue_color: 'Blue',
                dark_blue_color: 'Dark blue',
                purple_color: 'Purple',
                Violet_color: 'Violet',
                light_green_color: 'Light green',
                green_color: 'Green',
                pink_color: 'Pink',
                red_color: 'Red',
                yellow_color: 'Yellow',
                orange_color: 'Orange',
                brown_color: 'Brown',
                turquoise_color: 'Turquoise',
            }
        },
        ru: {
            translations: {
                print_btn: 'Печать',
                cancel_btn: 'Отмена',
                сreate_btn: 'Создать',
                delete_btn: 'Удалить',
                save_btn: 'Сохранить',
                edit_btn: 'Редактировать',
                ready_btn: 'Готово',
                add_btn: 'Добавить',
                welcome_text: 'Добро пожаловать',
                organigram: 'Органиграмма',
                choose_an_organigram: 'Выберите органиграмму',
                create_new: 'Создать новую',
                organization_name: 'Название организации',
                select_type_of_organigram: 'Выберите тип органиграммы',
                select_connecting_lines: 'Выберите соединительные линии',
                roll_call: 'Перекличка',
                by_surname: 'По фамилии',
                by_callsign: 'По позывному',
                by_department: 'По департаменту',
                no_name: 'Без имени',
                without_caller: 'Без позывного',
                without_department: 'Без департамента',
                employees: 'Сотрудников: ',
                begin: 'Начать',
                no_workers: 'Нет работников',
                without_filter: 'Без фильтра',
                without_status: 'Без статуса',
                select_status: 'Выберите статус',
                is_ill: 'Болен',
                vacation: 'Отпуск',
                business_trip: 'Командировка',
                deleting_an_ordinagram: 'Удаление ординаграммы',
                deleting_card: 'Удаление карточки',
                delete_question: 'Точно удалить ?',
                show_cards: 'Показать карточки',
                select_end_date: 'Выберите дату завершения',
                delete_status: 'Удалить статус',
                add_status: 'Добавить статус',
                select_color: 'Выберите цвет',
                card_placeholder: 'Имя и фамилия',
                documents: 'Документы',
                comments_hover_text: 'Комментарий',
                no_documents_placeholder: 'Документов нет',
                card_callsign: 'Позывной',
                card_position_placeholder: 'Должность',
                empty_input_placeholder: 'Пусто',
                department: 'Департамент',
                date_of_birth: 'Дата рождения',
                phone: 'Телефон',
                email: 'Почта',
                notes: 'Заметки',
                blue_color: 'Голубой',
                dark_blue_color: 'Синий',
                purple_color: 'Сиреневый',
                Violet_color: 'Фиолетовый',
                light_green_color: 'Салатовый',
                green_color: 'Зеленый',
                pink_color: 'Розовый',
                red_color: 'Красный',
                yellow_color: 'Желтый',
                orange_color: 'Оранжевый',
                brown_color: 'Коричневый',
                turquoise_color: 'Бирюзовый',
            }
        }
    },

    fallbackLng: "ru",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
