import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {api} from "../../../../../api/restClient";
import {getDetails, getDetailsCardType} from "../../actions/diagramActions/getDetailsCard";
import {detailsCardType} from "../../../../../types/detailsCardTypes";
import {GET_CARDS_ACTION, GET_DETAILS_CARD_ACTION, PUT_DETAILS_CARD_SAGA, PUT_FIELDS_SAGA} from "../../actionTypes";
import {getCardsAction} from "../../actions/diagramActions/getCards";


const putApiFields = (putObj) => {
  const {cardId, ordmId, params} = putObj
  return api.put(`v1/organigram/${ordmId}/cards/${cardId}/fields/`, params.fields)
    .catch((err) => console.log(err))
}

const getApiCards = (ordmId) => {
  return api.get(`v1/organigram/${ordmId}/cards/`)
    .catch((err) => console.log(err))
}

function* putFieldsAsync(action: getDetailsCardType) { // PUT
  const putObj = {
    cardId: action.payload.card_id,
    ordmId: action.payload.ordinagram_id,
    params: action.payload.details
  }
  const response = yield call(putApiFields, putObj)
  const details: detailsCardType = yield select((state) => state.homePage.detailsCard)
  const newDetails: detailsCardType = {
    ...details,
    documents: details.documents,
    fields: response.data,
  }
  yield put(getDetails({
    type: GET_DETAILS_CARD_ACTION,
    payload: {
      card_id: putObj.cardId,
      ordinagram_id: putObj.ordmId,
      details: newDetails
    }
  } as getDetailsCardType))
  const responseCards = yield call(getApiCards, action.payload.ordinagram_id)
  yield put(getCardsAction({
    type: GET_CARDS_ACTION,
    payload: {data: responseCards.data, ordinagramId: action.payload.ordinagram_id}
  }))
}


export function* putFieldsSagas() {
  yield takeEvery(PUT_FIELDS_SAGA, putFieldsAsync)
}
