import {all, put, call, select, takeEvery} from "redux-saga/effects";
import {api} from "../../../../../api/restClient";
import {DELETE_ORDINAGRAM_SAGA, GET_CARDS_ACTION, GET_CARDS_SAGA, GET_ORDINAGRAMS_ACTION} from "../../actionTypes";
import {cardType, connectLinesType, ordinagramType} from "../../../../../types/types";
import {getCardsAction} from "../../actions/diagramActions/getCards";

const deleteApiOrdm = (id) => {
  return api.delete(`v1/organigram/${id}`)
    .catch((err) => console.log(err))
}

const getApiOrdm = () => {
  return api.get('v1/organigram/')
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

function* deleteOrdmAsync(action) {
  yield call(deleteApiOrdm, action.payload.id)
  const data: ordinagramType[] = yield call(getApiOrdm)
  yield put({type: GET_ORDINAGRAMS_ACTION, payload: data})
  if (data.length !== 0) {
    yield put({type: GET_CARDS_SAGA, payload: data.length !== 0 ? data[0].id : null})
  } else {
    yield put(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {data: {cards: [], connection_lines: []}, ordinagramId: null}
    }))
  }
}

export function* deleteOrdmSagas() {
  yield takeEvery(DELETE_ORDINAGRAM_SAGA, deleteOrdmAsync)
}