import React, {FC, useState} from "react";
import styles from './documents.module.scss';
import {cardType} from "../../types/types";
import {useSelector} from "react-redux";
import {appGlobalStore} from "../../store/root-reducer";
import {labelRedSvg, labelYellowSvg} from "../data-card/images";
import {indicatorLabelColor} from "../../utils/indicatorLabelColor";
import {useTranslation} from "react-i18next";

type DocumentsType = {
  card: cardType
  className: string
}

const DocumentsModal: FC<DocumentsType> = ({
                                             card,
                                             className,
                                           }) => {
  const [t] = useTranslation();
  const [textareaFocus, setTextareaFocus] = useState(false)

  const focusTextarea = (e) => {
    setTextareaFocus(true)
  }


  return (
    <div onFocus={(e) => focusTextarea(e)}
         className={className}
         style={{
           border: `2px solid ${textareaFocus ? '#0274ac' : className ? '#53a9d773' : className && textareaFocus ? '#0274ac' : 'transparent'}`,
           borderRadius: 8,
         }}>
      {
        card.documents.length !== 0 ? card.documents.map(item => {
            return (
              <div className="d-flex" style={{borderBottom: '1px solid #e9e9e9', margin: '4px 8px'}}>
                <p className={`${styles.text}`}>{item.name}</p>
                {indicatorLabelColor(item?.time_before_expiration) === 0 && <img src={labelRedSvg} alt="red"/>}
                {indicatorLabelColor(item?.time_before_expiration) === (1 || 2) &&
                <img src={labelYellowSvg} alt="yellow"/>}
                <p className={`${styles.text} ${styles.text_date}`}>{item.time_before_expiration}</p>
              </div>

            )
          })
          : <p>{t('no_documents_placeholder')}</p>
      }

    </div>
  )
}

export default DocumentsModal;
