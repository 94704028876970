import {all, put, call, takeEvery, select} from "redux-saga/effects";
import {GET_CARDS_ACTION, GET_DETAILS_CARD_ACTION, POST_AVATAR_SAGA} from "../../actionTypes";
import {api} from "../../../../../api/restClient";
import {getCardsAction, getCardsActionType} from "../../actions/diagramActions/getCards";
import {getDetails, getDetailsCardType} from "../../actions/diagramActions/getDetailsCard";

const postApiAvatar = (postObj) => {
  return api.sendFile(`v1/avatar/`, postObj)
    .catch((err) => console.log(err))
}
const getApiCards = (id) => {
  return api.get(`v1/organigram/${id}/cards/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

const getApiDetailsCard = (getObj) => {
  const {cardId, ordmId} = getObj
  return api.get(`v1/organigram/${ordmId}/cards/${cardId}/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

function* postAvatarAsync(action) {
  try {
    const getDetailsCardObj = {cardId: action.payload.card_id, ordmId: action.payload.ordinagram_id}
    yield call(postApiAvatar, action.payload.formData)
    const {cards, connection_lines} = yield call(getApiCards, action.payload.ordinagram_id)
    const details = yield call(getApiDetailsCard, getDetailsCardObj)

    yield put(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {
          cards,
          connection_lines,
        },
        ordinagramId: action.payload.ordinagram_id
      }
    } as getCardsActionType))
    yield put(getDetails({
      type: GET_DETAILS_CARD_ACTION,
      payload: {
        card_id: action.payload.card_id,
        ordinagram_id: action.payload.ordinagram_id,
        details: details,
        requestType: ""
      }
    } as getDetailsCardType))
  } catch (err) {

  }

}

export function* postAvatarSagas() {
  yield takeEvery(POST_AVATAR_SAGA, postAvatarAsync)
}
