import {GENERATE_POST_FIELDS_ACTION} from "../../actionTypes";
import {FieldsType} from "../../../../../types/detailsCardTypes";

export type generatePostFieldsType = {
  type: typeof GENERATE_POST_FIELDS_ACTION,
  payload: {
    card_id: string,
    ordinagram_id: number,
    fields: FieldsType[],
  }
}

export const generatePostFields = (data: generatePostFieldsType) => ({
  type: data.type,
  payload: data.payload
})