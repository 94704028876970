import React from 'react'
import './loader.scss'

export const Loader: React.FC<any> = ({ style = null}) => {
  return (
    <div className={style ? 'lds-spinner small-spinner' : 'lds-spinner'} style={style || {}}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
