import { useState, useEffect } from "react";
import { UserCheck, UserCard, FilterSelect } from "./parts";
import "./side-panel-right.scss";
import { useTranslation } from "react-i18next";
import {sortArray} from "../../../utils/sort";

const SidePanelRight = (props) => {
  const {
    isOpen,
    isUserCheckStarted,
    startChecking,
    finishChecking,
    cards,
    toggleRightSidePanel,
  } = props;

  const sidePanelClassName = `${isOpen && "side-panel-right__show-right"}`;

  const [count, setCount] = useState(0);
  const [t] = useTranslation();
  const [selectedValue, setSelectedValue] = useState(t('by_surname'));
  const users = [...cards];


  useEffect(() => {
    setSelectedValue(t('by_surname'))
  }, [t('by_surname')])

  const isFiltered = users.find((user) => user.filterStatus.value !== "");

  const counter = (checked: boolean) => {
    if (checked) {
      setCount((prev) => prev + 1);
    } else {
      setCount((prev) => prev - 1);
    }
  };

  const resetCount = () => {
    setCount(0);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
  };

  const sortBy = (array, sortParameter) => {
    let sortedStringArray = []
    switch (sortParameter) {
      case t('by_surname'):
        // @ts-ignore
        sortedStringArray = sortArray(array.filter(item => item['full_name']?.length)?.map(i => i['full_name']))
         return array.sort((a, b) => {
          return sortedStringArray.indexOf(a['full_name']) - sortedStringArray.indexOf(b['full_name'])
        });

      case t('by_callsign'):
        sortedStringArray = sortArray(array.filter(item => item['call_sign']?.length)?.map(i => i['call_sign']))
        return array.sort((a, b) => {
          return sortedStringArray.indexOf(a['call_sign']) - sortedStringArray.indexOf(b['call_sign'])
        });

      case t('by_department'):
        sortedStringArray = sortArray(array.filter(item => item['department']?.length)?.map(i => i['department']))
        return array.sort((a, b) => {
          return sortedStringArray.indexOf(a['department']) - sortedStringArray.indexOf(b['department'])
        });
      default:
        return array
    }
  };

  const filterByStatus = (usersArr, filterBy) => {
    switch (filterBy) {
      case 'is_ill':
        return usersArr.filter((user) => user.status.status === 'is_ill');
      case 'vacation':
        return usersArr.filter((user) => user.status.status === 'vacation');
      case 'business_trip':
        return usersArr.filter((user) => user.status.status === 'business_trip');
      case 'without_status':
        return usersArr.filter((user) => user.status.status === "");
      default:
        return usersArr;
    }
  };

  const filteredUsers = filterByStatus(
    users.length && users,
    users.length && users[0].filterStatus.value
  );

  const filteredStatus = users.length && users[0].filterStatus.value;

  const titleName = (status: string) => {
    switch (status) {
      case 'is_ill':
        return t('is_ill');
      case 'vacation':
        return t('vacation');
      case 'business_trip':
        return t('business_trip');
      case 'without_status':
        return t('without_status');
    }
  };

  const titleToProps = titleName(filteredStatus);

  useEffect(() => {
    !isOpen && isFiltered && toggleRightSidePanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltered]);

  useEffect(() => {
    isFiltered === undefined && isOpen && toggleRightSidePanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltered]);

  return (
    <>
      <section className={`side-panel-right ${sidePanelClassName}`}>
        <div className="filter">
          <h3 className="side-panel-right__title">
            {titleToProps ? titleToProps : t('roll_call')}
          </h3>
          <FilterSelect onChange={handleSelect} value={selectedValue} />
        </div>

        <div className="users__cards">
          {filteredUsers.length
            ? sortBy(filteredUsers, selectedValue).map((user) => {
                  return (
                    <UserCard
                      key={user.id}
                      name={user.full_name}
                      callSign={user.call_sign}
                      color={user.color_card}
                      department={user.department}
                      disableCheckbox={isUserCheckStarted}
                      onChange={counter}
                      isFiltered={isFiltered}
                    />
                  );
                })
            : t('no_workers')}
        </div>
        <UserCheck
          isUserCheckStarted={isUserCheckStarted}
          startChecking={startChecking}
          finishChecking={finishChecking}
          totalCount={users.length}
          count={count}
          resetCount={resetCount}
          isFiltered={isFiltered}
          title={titleToProps}
        />
      </section>
    </>
  );
};

export default SidePanelRight;
