import {PREVIEW_PRINT_ACTION} from "../../actionTypes";
import {cardType, connectLinesType} from "../../../../../types/types";

export type previewPrintActionType = {
  type: typeof PREVIEW_PRINT_ACTION,
  payload: {
    A3: boolean
    A4: boolean
    previewMode: boolean
  }
}

export const previewPrintAction = (data) => ({
  type: data.type,
  payload: data.payload
})