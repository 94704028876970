import React, {FC, useState} from "react";
import styles from "../menu-card.module.scss";
import clsx from "clsx";
import {Save, saveType} from "../../save/save";
import {cardType, ordinagramType} from "../../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {
  GET_CARDS_ACTION,
  GET_ORDINAGRAMS_ACTION,
  PATCH_CARD_ACTION,
  PATCH_CARD_SAGA,
  PATCH_DETAILS_ACTION, PATCH_DETAILS_CARD_SAGA
} from "../../../pages/home/redux-sagas/actionTypes";
import {patchCard} from "../../../pages/home/redux-sagas/sagas/poll/patchCardAsync";
import {patchCardAction} from "../../../pages/home/redux-sagas/actions/diagramActions/patchCardAction";
import {appGlobalStore} from "../../../store/root-reducer";
import {getCardsAction} from "../../../pages/home/redux-sagas/actions/diagramActions/getCards";
import {getOrdinagrams} from "../../../pages/home/redux-sagas/actions/diagramActions/getOrdinagrams";
import {colors} from "../constant";
import {patchDetailsCardAction} from "../../../pages/home/redux-sagas/actions/diagramActions/patchDetailsCardAction";
import {patchDetails} from "../../../pages/home/redux-sagas/sagas/poll/patchDetailsCardAsync";
import {useTranslation} from "react-i18next";


type ColorType = {
  card: cardType
}

const ColorPanel: FC<ColorType> = ({
                                     card
                                   }) => {
  const dispatch = useDispatch()
  const initialElements = useSelector(({homePage}: appGlobalStore) => homePage.cards)
  const connectLines = useSelector(({homePage}: appGlobalStore) => homePage.connectLines)
  const ordinagrams = useSelector(({homePage}: appGlobalStore): ordinagramType[] => homePage.ordinagrams)
  const [selectedColor, setSelectedColor] = useState<string>('')
  const [closeColorPanel, setCloseColorPanel] = useState(false)
  const [oldColor] = useState<string>(card.color_card)
  const [t] = useTranslation();

  const handleButtonColor = (e, color: string) => {
    setSelectedColor(color)
    const objCard = {
      card_id: card.id,
      ordinagram_id: card.ordinagram_id,
      property: {color_card: color},
      type: patchCard.color_card
    }

    const objDetailsCard = {
      card_id: card.id,
      ordinagram_id: card.ordinagram_id,
      property: {color_card: color},
      type: patchDetails.color_card
    }

    dispatch(patchCardAction({
      type: PATCH_CARD_ACTION,
      payload: objCard
    }))
    dispatch(patchDetailsCardAction({
      type: PATCH_DETAILS_ACTION,
      payload: objDetailsCard,
    }))
  }

  const onSubmitColorStatus = (e, card: cardType, selectColor) => {
    e.preventDefault()
    const newCards = initialElements.map((item: cardType) => {
      if (item.id === card.id) return {...item, openMenuCard: false, openMenuDetails: false}
      return {...item}
    })
    const newOrdinagrams: ordinagramType[] = ordinagrams.map((ordm: ordinagramType): ordinagramType => {
      if (ordm.id === card.ordinagram_id) {
        return {...ordm, zoomOnScroll: closeColorPanel ? true : !closeColorPanel, isDraggableCard: closeColorPanel ? true : !closeColorPanel}
      }
      return {...ordm}
    })
    dispatch(getOrdinagrams({
      type: GET_ORDINAGRAMS_ACTION,
      payload: newOrdinagrams
    }))
    dispatch(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {
          cards: newCards,
          connection_lines: connectLines,
        },
        ordinagramId: Number(card.ordinagram_id)
      }
    }))
    dispatch({
      type: PATCH_CARD_SAGA,
      payload: {
        card_id: card.id,
        property: closeColorPanel ? oldColor : selectColor === '' ? oldColor : selectColor,
        ordinagram_id: card.ordinagram_id,
        type: patchCard.color_card
      }
    })
  }

  return (
    <div className={styles.menu}>
      <p className={clsx(styles.prompt)}>{t('select_color')}</p>
      <div className={styles.form}>
        {colors.map((item, index) => {
          const color = Object.keys(item)[0]
          return (
            <button onClick={(e) => handleButtonColor(e, color)}
                    onDoubleClick={(e) => onSubmitColorStatus(e, card, selectedColor)}
                    className={styles.text}
                    style={{borderColor: selectedColor === color && '#1D8BC5'}}
                    key={color + index}
            >
              <span className={styles.iconColor} style={{background: Object.keys(item)[0] }}/>
              {t(`${Object.values(item)[0]}`)}
            </button>
          )
        })}
        <Save type={saveType.settingsColorCard}
              selectedColor={selectedColor}
              setCloseColorPanel={setCloseColorPanel}
              onSubmitColorStatus={onSubmitColorStatus}
              className={saveType.settingsColorCard}
              card={card}
              text={t('save_btn')}/>
      </div>
    </div>
  )
}

export default ColorPanel;
