import {call, all, put, takeEvery, select} from "@redux-saga/core/effects";
import {PATCH_DETAILS_CARD_SAGA} from "../../actionTypes";

export const patchDetails = {
  color_card: 'color_card',
}

function* patchDetailsCardAsync(action) {

}

export function* patchDetailsCardSagas() {
  yield takeEvery(PATCH_DETAILS_CARD_SAGA, patchDetailsCardAsync)
}
