import React, {FC, useEffect, useState} from "react";
import styles from './value-inputs.module.scss';
import {detailsCardType, FieldsType} from "../../../../types/detailsCardTypes";
import {calendarSvg, mailSvg, mapPinSvg, phoneSvg, textSvg} from "../../images";
import {valueInputsArray} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {appGlobalStore} from "../../../../store/root-reducer";
import {GET_DETAILS_CARD_ACTION} from "../../../../pages/home/redux-sagas/actionTypes";
import {getDetailsCardType} from "../../../../pages/home/redux-sagas/actions/diagramActions/getDetailsCard";


type ValueInputType = {
  field: FieldsType
  card_id: string
  ordinagram_id: number
  changeDetails: detailsCardType
  setChangeDetails: (obj: detailsCardType) => void
}

const ValueInput: FC<ValueInputType> = ({
                                          field,
                                          card_id,
                                          ordinagram_id,
                                          changeDetails,
                                          setChangeDetails,
                                        }) => {
  const dispatch = useDispatch();
  const [selectedInput, setSelectedInput] = useState(0)
  const [valueInput, setValueInput] = useState('')

  const onChangeInput = (e) => {
    setValueInput(e.target.value)
    const newFields = changeDetails.fields.map((item: FieldsType) => {
      if (!item.id) {
        return {...item, title: e.target.value}
      }
      return {...item}
    })
    setChangeDetails({...changeDetails, fields: newFields})
    dispatch({
      type: GET_DETAILS_CARD_ACTION,
      payload: {
        card_id: card_id,
        ordinagram_id: ordinagram_id,
        details: {...changeDetails, fields: newFields},
      }
    } as getDetailsCardType)
  }


  const handleChoice = (index, type) => {
    setSelectedInput(index)
    const newFields = changeDetails.fields.map((item: FieldsType) => {
      if (!item.id) {
        return {...item, type, icon: valueInputsArray.find((icon) => type === icon.type).icon}
      }
      return {...item}
    })
    setChangeDetails({...changeDetails, fields: newFields})
    dispatch({
      type: GET_DETAILS_CARD_ACTION,
      payload: {
        card_id: card_id,
        ordinagram_id: ordinagram_id,
        details: {...changeDetails, fields: newFields},
      }
    } as getDetailsCardType)
  }


  return (
    <div className={styles.wrapper}>
      <div className="d-flex align-items-center" style={{}}>
        <input className={styles.input} onChange={onChangeInput} type="text" maxLength={20} placeholder={field.title}/>
        <p className={styles.count}>{valueInput.length}/20</p>
      </div>
      <p className={styles.prompt}>Выберите тип поля</p>
      {valueInputsArray.map((item, index) => (
        <div onClick={() => handleChoice(index, item.type)}
             style={{background: item.id === selectedInput && '#EBEFF2'}}
             className={styles.choiceTitle}>
          <img src={item.icon} alt="text"/>
          <p className={styles.title}>{item.title}</p>
        </div>
      ))}
    </div>

  )
}

export default ValueInput