import React from "react";
import styles from './share.module.scss';
import {
  EmailShareButton,
  EmailIcon,
  OKIcon,
  OKShareButton,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
} from "react-share";
import {mailSvg} from "./images";
import {Loader} from "../../ui-kit";


const Share = ({urlDocument, setShareOpen, fileName, style = {}}) => {

  return (
    <div className={styles.share} style={style}>
      <TelegramShareButton
        onClick={() => setShareOpen(false)}
        url={`${urlDocument}`}
        className="Demo__some-network__share-button"
      >
        <TelegramIcon style={{marginRight: 5}} size={22} round/>
      </TelegramShareButton>
      <WhatsappShareButton
        onClick={() => setShareOpen(false)}
        url={`${urlDocument}`}
        className="Demo__some-network__share-button"
      >
        <WhatsappIcon style={{marginRight: 5}} size={22} round/>
      </WhatsappShareButton>
      <OKShareButton
        onClick={() => setShareOpen(false)}
        url={`${urlDocument}`}
        className="Demo__some-network__share-button"
      >
        <OKIcon style={{marginRight: 5}} size={22} round/>
      </OKShareButton>
      <VKShareButton
        onClick={() => setShareOpen(false)}
        url={`${urlDocument}`}
        className="Demo__some-network__share-button"
      >
        <VKIcon style={{marginRight: 5}} size={22} round/>
      </VKShareButton>
      <ViberShareButton
        onClick={() => setShareOpen(false)}
        url={`${urlDocument}`}
        className="Demo__some-network__share-button"
      >
        <ViberIcon style={{marginRight: 5}} size={22} round/>
      </ViberShareButton>
      <TwitterShareButton
        onClick={() => setShareOpen(false)}
        url={`${urlDocument}`}
        className="Demo__some-network__share-button"
      >
        <TwitterIcon style={{marginRight: 5}} size={22} round/>
      </TwitterShareButton>
      <EmailShareButton
        body={`Файл "${fileName}" можно скачать по ссылке: `}
        openShareDialogOnClick={true}
        onClick={() => setShareOpen(false)}
        url={`${urlDocument}`}
        className="Demo__some-network__share-button"
      >
        <img src={mailSvg} alt="mail" style={{marginRight: 5, width: 20, height: 20}}/>
      </EmailShareButton>
    </div>
  )
}

export default Share;
