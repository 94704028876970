import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as actions from "./redux-sagas/actions";
import * as selectors from "./redux-sagas/selectors";

const mapStateToProps = createStructuredSelector({
  home: selectors.homePageStateSelector,
  user: selectors.userSelector,
  cards: selectors.cardsSelector,
});

const mapDispatchToProps = {
  toggleRightSidePanel: actions.toggleRightSidePanel,
  toggleLeftSidePanel: actions.toggleLeftSidePanel,
  startChecking: actions.startUserCheck,
  finishChecking: actions.finishUserCheck,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
