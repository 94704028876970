import "./side-panel-left.scss";
import { CustomLink } from "../../../ui-kit";
import * as Image from "./images";
import {useTranslation} from "react-i18next";
import {LanguageSelect} from "../../language-switcher";
import React from "react";

const SidePanelLeft = (props) => {
  const { isOpen, user } = props;
  const [t] = useTranslation();

  const sidePanelClassName = `${isOpen && "side-panel__show"}`;

  return (
    <>
      <section className={`side-panel ${sidePanelClassName}`}>
        <div className="user">
          <div className="user__avatar">
            {user.avatar ? (
              <img
                src={user.avatar}
                alt="фото пользователя"
                className="user__avatar"
              />
            ) : (
              <Image.Avatar />
            )}
          </div>
          <div className="user__info">
            <p className="user__name">
              {user.name ? user.name : t('welcome_text')}
            </p>
            <p className="user__email">{user.email && user.email}</p>
          </div>
        </div>
        <nav className="side-panel__nav">
          {/* поменять на button в процессе */}
          <CustomLink
            to="/"
            className="side-panel__link"
            textClassName="side-panel__text"
            Icon={Image.OrganigramSvg}
            text={t('organigram')}
          />
        </nav>
        <div className="side-panel_footer">
          <LanguageSelect />
        </div>

      </section>
    </>
  );
};

export default SidePanelLeft;
