import { Form } from "./parts";
import { connector } from "./container";

const RestorePasswordInfo = (props) => {
  const { authorization } = props;

  return (
    <Form
      linkTo="/login"
      linkText="Вернуться на страницу входа"
      title="Восстановление пароля"
    >
      <p className="authForm__text">
        Мы отправили ссылку для восстановления пароля на почту
        <span> {authorization.email}</span>. Если вы не получили ссылку в
        течение 15 минут, убедитесь, что адрес почты правильный.
      </p>
    </Form>
  );
};

export default connector(RestorePasswordInfo);
