import {all, put, call, takeEvery, select} from "redux-saga/effects";
import {DELETE_CARD_SAGA, GET_CARDS_ACTION} from "../../actionTypes";
import {api} from "../../../../../api/restClient";
import {getCardsAction} from "../../actions/diagramActions/getCards";



const deleteApiCard = (delObj) => {
  const {cardId, ordmId} = delObj
  return api.delete(`v1/organigram/${ordmId}/cards/${cardId}/`)
    .catch((err) => console.log(err))
}

const getApiCards = (id) => {
  return api.get(`v1/organigram/${id}/cards/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}


function* deleteCardAsync (action)  {
  const delObj = {cardId: action.payload.card_id, ordmId: action.payload.ordinagram_id}
  yield call(deleteApiCard, delObj)
  const response = yield call(getApiCards, action.payload.ordinagram_id)
  yield put(getCardsAction({
    type: GET_CARDS_ACTION,
    payload: {data: response, ordinagramId: action.payload.ordinagram_id}}))
}
export function* deleteCardSagas () {
  yield takeEvery(DELETE_CARD_SAGA, deleteCardAsync)
}

