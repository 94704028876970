import * as Image from "../../images";

export type Props = {
  errorText: string;
  onChange: any;
};

export const InputEmail = (props: Props) => {
  const { onChange, errorText } = props;

  return (
    <>
      <div className="authForm__inputWrapper">
        <div className="authForm__icon">
          <Image.Email />
        </div>
        <input
          name="email"
          type="email"
          className="authForm__input"
          placeholder="Электронная почта"
          onChange={onChange}
          required
          minLength={5}
          autoComplete="new-password"
        />
      </div>
      <span className="validation_error">{errorText}</span>
    </>
  );
};
