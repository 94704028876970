
import { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { ProtectedRoute } from "./components";
import HomePage from "../pages/home/HomePage";
import { FileViewPage } from "../pages/file-viewer/FileViewPage";

import {
  Login,
  Register,
  RestorePasswordForm,
  RestorePasswordInfo,
  NewPassword,
} from "../pages/authorization";
import { Loader } from "../ui-kit";
import { verifyToken, handleSetUser } from "../api/authApi";
import { connector } from "./container";
import queryString from "query-string";


const user = localStorage.getItem("user");
const userName = localStorage.getItem("userName");
const userAvatar = localStorage.getItem("userAvatar");
const secret = localStorage.getItem("secret");
const userEmail = localStorage.getItem("userEmail");

const initialState = {
  user: (user && JSON.parse(user)) || {},
  userName: (userName && JSON.parse(userName)) || "",
  userAvatar: (userAvatar && JSON.parse(userAvatar)) || "",
  secretLink: (secret && JSON.parse(secret)) || "",
  userEmail: (userEmail && JSON.parse(userEmail)) || "",
};

const Router = (props) => {
  const {
    authorization,
    loggedIn,
    setSecretLink,
    setLoadingTrue,
    setLoadingFalse,
    setFullUser,
  } = props;

  const [user, setUser] = useState(initialState.user);

  const history = useHistory();
  const location = useLocation()
  // eslint-disable-next-line no-restricted-globals
  const values = queryString.parse(location.search);

  const tokenCheck = async () => {
    setLoadingTrue();
    const token = user;
    try {
      await verifyToken(token).then((res) => {
        if (res === undefined) {
          setLoadingFalse();
          throw new Error("error in verification");
        } else {
          loggedIn();
          setUser(res);
           history.push(location.pathname === "/file/view" ? '/file/view' : '/home');
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (JSON.stringify(values) !== "{}") {
      setFullUser({
        email: values.user,
        name: values.full_name,
        avatar: values.avatar,
      });
      const token = { token: values.token };
      localStorage.setItem("userName", JSON.stringify(values.full_name));
      localStorage.setItem("userAvatar", JSON.stringify(values.avatar));
      handleSetUser(token, values.user);
      setUser(values.token);
    } else {
      setUser(localStorage.user);
      setSecretLink(initialState.secretLink);
      setFullUser({
        email: initialState.userEmail,
        name: initialState.userName,
        avatar: initialState.userAvatar,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorization.loggedIn]);

  useEffect(() => {
    if (JSON.stringify(user) === "{}") {
    } else {
      tokenCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return authorization.loading ? (
    <Loader />
  ) : (
    <Switch>
      <ProtectedRoute
        exact
        path="/home"
        component={HomePage}
        loggedIn={authorization.loggedIn}
      />
      <Route exact path='/file/view' component={FileViewPage} />
      <Route path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/restore-password" component={RestorePasswordForm} />
      <Route
        exact
        path="/restore-password-info"
        component={RestorePasswordInfo}
      />
      <Route
        path={`/newpassword`}
        component={NewPassword}
      />

      <Route exact path="*">
        <Redirect to="/home" />
      </Route>
    </Switch>
  );
};

export default connector(Router);
