import React, {FC, useEffect, useState} from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker'; //@wojtekmaj/react-daterange-picker/dist/entry.nostyle
// import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'
import styles from "../menu-card.module.scss";
import clsx from "clsx";
import {statuses} from "../constant";
import {Save, saveType} from "../../save/save";
import {cardType, ordinagramType} from "../../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {appGlobalStore} from "../../../store/root-reducer";
import {getOrdinagrams} from "../../../pages/home/redux-sagas/actions/diagramActions/getOrdinagrams";
import {
  GET_CARDS_ACTION,
  GET_ORDINAGRAMS_ACTION,
  PATCH_CARD_STATUS_SAGA
} from "../../../pages/home/redux-sagas/actionTypes";
import {getCardsAction} from "../../../pages/home/redux-sagas/actions/diagramActions/getCards";
import {patchCard} from "../../../pages/home/redux-sagas/sagas/poll/patchCardAsync";
import {convertNewDate} from "../../../utils/convertNewDate";
import {changeDateFormat} from "../../../utils/convertDateYYYY-MM-DD";
import '../style.scss'
import {useTranslation} from "react-i18next";

const emptyStatus = {
  status: "",
  opening_date: "",
  closing_date: "",
  color_string: "",
}
// для closing_data и opening_date используется формат YYYY-MM-DD


type StatusPanelType = {
  card: cardType
}

const StatusPanel: FC<StatusPanelType> = ({card}) => {
  const dispatch = useDispatch()
  const [t] = useTranslation();
  const initialElements = useSelector(({homePage}: appGlobalStore) => homePage.cards)
  const connectLines = useSelector(({homePage}: appGlobalStore) => homePage.connectLines)
  const ordinagrams = useSelector(({homePage}: appGlobalStore): ordinagramType[] => homePage.ordinagrams)
  const [selectedColor, setSelectedColor] = useState<string>('')
  const [dateForCalendar, setDateForCalendar] = useState([new Date(), new Date()])
  const [patchStatus, setPatchStatus] = useState(emptyStatus)
  const disabled = !(patchStatus.status !== "" && patchStatus.closing_date.length === 10)

  const handleButtonStatus = (e, color, status) => {
    setSelectedColor(color)
    setPatchStatus({
      ...patchStatus, status: status, color_string: color
    })
  }

  useEffect(() => {
    const dateRange = document?.getElementsByClassName('react-daterange-picker__wrapper')
    dateRange[0]?.classList?.add('custom_wrapper')
    const dateRangeInputs = document?.getElementsByClassName('react-daterange-picker__wrapper')
    dateRangeInputs[0]?.classList?.add('custom_inputGroup')
  }, [])

  const onChangeDate = (date) => {
    setPatchStatus({
      ...patchStatus,
      closing_date: convertNewDate(date ? date[1] : null),
      opening_date: convertNewDate(date ? date[0] : null),
    })
    setDateForCalendar(date)
  }

  const onSubmitStatus = (e, card, clickClose) => {
    e.preventDefault()
    const newCards = initialElements.map((item: cardType) => {
      if (item.id === card.id) return {...item, openMenuCard: false,openMenuDetails: false}
      return {...item}
    })
    const newOrdinagrams: ordinagramType[] = ordinagrams.map((ordm: ordinagramType): ordinagramType => {
      if (ordm.id === card.ordinagram_id) {
        return {...ordm, zoomOnScroll: true, isDraggableCard: true}
      }
      return {...ordm}
    })
    dispatch(getOrdinagrams({
      type: GET_ORDINAGRAMS_ACTION,
      payload: newOrdinagrams
    }))
    dispatch(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {
          cards: newCards,
          connection_lines: connectLines,
        },
        ordinagramId: Number(card.ordinagram_id)
      }
    }))
    !clickClose && dispatch({
      type: PATCH_CARD_STATUS_SAGA,
      payload: {
        card_id: card.id,
        property: patchStatus,
        ordinagram_id: card.ordinagram_id,
        type: patchCard.status
      }
    })
  }


  const deleteStatus = () => {
    const newCards = initialElements.map((item: cardType) => {
      if (item.id === card.id) return {...item, openMenu: false}
      return {...item}
    })
    const newOrdinagrams: ordinagramType[] = ordinagrams.map((ordm: ordinagramType): ordinagramType => {
      if (ordm.id === card.ordinagram_id) {
        return {...ordm, zoomOnScroll: true, isDraggableCard: true}
      }
      return {...ordm}
    })
    dispatch(getOrdinagrams({
      type: GET_ORDINAGRAMS_ACTION,
      payload: newOrdinagrams
    }))
    dispatch(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {
          cards: newCards,
          connection_lines: connectLines,
        },
        ordinagramId: Number(card.ordinagram_id)
      }
    }))
    dispatch({
      type: PATCH_CARD_STATUS_SAGA,
      payload: {
        card_id: card.id,
        property: emptyStatus,
        ordinagram_id: card.ordinagram_id,
        type: patchCard.status
      }
    })
  }

  return (
    <div className={styles.menu}>
      <div style={{padding: 8}}>
        <p className={clsx(styles.prompt)}>{t('select_status')}</p>
        {card.status.status !== "" &&
        <p onClick={deleteStatus} className={clsx(styles.text)}>
          <span className={styles.icon} style={{background: '#C2CFE0'}}/>
          {t('delete_status')}
        </p>}
        {
          card.status.status ?
              <p className={`${styles.text} ${styles.text_activeStatus}`}>
                <span className={styles.icon} style={{background: card.status.color_string}} />
                {t(`${card.status.status}`)}
              </p>
            : statuses.map(item => {
              const color = Object.keys(item)[0]
              const status = Object.values(item)[0]
              return (
                <p key={color} onClick={(e) => handleButtonStatus(e, color, status)}
                   className={styles.text}
                   style={{borderColor: selectedColor === color && '#1D8BC5'}}>
                  <span className={styles.icon} style={{background: color}}/>
                  {t(`${status}`)}
                </p>
              )
            })
        }
      </div>
      <hr
        style={{
          backgroundColor: '#959499',
          height: 0.5,
          border: 'none',
          margin: 0,
        }}
      />
      {!card.status.status &&
      <p className={clsx(styles.prompt)} >{t('select_end_date')}</p>}
      {card?.status.status ?
        <p className={styles.calendarDateRange}
        >{changeDateFormat(card.status.opening_date)}<span> - </span>{changeDateFormat(card.status.closing_date)}
        </p>
        : <DateRangePicker
          // onChange={onChange}
          onChange={(date) => onChangeDate(date)}
          value={dateForCalendar}
          className={styles.calendarDateRange}
        />}

      <Save type={saveType.settingsStatusCard}
            selectedStatus={selectedColor}
            onSubmitStatus={onSubmitStatus}
            className={saveType.settingsStatusCard}
            card={card}
            text={t('save_btn')}
            disabled={disabled}/>
    </div>

  )
}

export default StatusPanel;
