import * as t from '../../actionTypes'

export const loggedIn = () => {
  return {
    type: t.LOGGED_IN,
  }
}

export const loggedOut = () => {
  return {
    type: t.LOGGED_OUT,
  }
}