import {all, put, call, takeEvery, select} from "redux-saga/effects";
import {GET_CARDS_ACTION, POST_CARD_SAGA} from "../../actionTypes";
import {api} from "../../../../../api/restClient";
import {cardPostType, cardType} from "../../../../../types/types";
import {addCardSide} from "../../../../../components/diagrams/Main";
import {getCardsAction} from "../../actions/diagramActions/getCards";

const postApiCard = (postObj) => {
  const {params, ordmId} = postObj
  return api.post(`v1/organigram/${ordmId}/cards/`, params)
    .catch((err) => console.log(err))
}

const getApiCards = (id) => {
  return api.get(`v1/organigram/${id}/cards/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}


function* createNewCardAsync(action) {
  const card: cardType = action.payload.params
  const typeSide = action.payload.type
  try {
    const position = (card: cardType, typeSide) => {
      switch (typeSide) {
        case addCardSide.ADD_LEFT:
          return {x: card.position.x - 390, y: card.position.y}
        case addCardSide.ADD_RIGHT:
          return {x: card.position.x + 390, y: card.position.y}
        case addCardSide.ADD_BOTTOM:
          return {x: card.position.x, y: card.position.y + 260}
      }
    }
    const postData: cardPostType = {
      full_name: '',
      color_card: card.color_card,
      position: position(card, typeSide),
      call_sign: '',
      working_position: '',
      comments: '',
      source_card_id: typeSide === addCardSide.ADD_BOTTOM ? action.payload.params.id : 0,
      // "source_card_id" при post card это айдишник карточки от которой мы создаемся,
      // необходим чтобы бекенд создал объект линии соединения карточек между прерыдущей и новосозданной
    }
    const postObj = {params: postData, ordmId: card.ordinagram_id}

    yield call(postApiCard, postObj)
    const response = yield call(getApiCards, card.ordinagram_id)
    yield put(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {data: response, ordinagramId: card.ordinagram_id}
    }))
  } catch (err) {
    console.log(err)
  }
}

export function* createNewCardSagas() {
  yield takeEvery(POST_CARD_SAGA, createNewCardAsync)
}