import React, {FC, useEffect, useState} from "react";
import './calendar.module.scss'
import Calendar from 'react-calendar';
import closeSvg from './images/close.svg';
import styles from './calendar.module.scss'
import {convertNewDate} from "../convertNewDate";

type DateCalendarType = {
  type?: string
  idField?: number
  patchDateDocument?: {
    file: {},
    completion_date: string,
  }
  setValueDate?: (date: any) => void
  setOpenCalendar?: (obj: { id: any, open: boolean }) => void
  setValueDateOfBirth?: (date: any) => void
  setPatchDateDocument?: (obj: any) => void
}

const DateCalendar: FC<DateCalendarType> = ({
                                              type,
                                              idField,
                                              patchDateDocument,
                                              setValueDate,
                                              setValueDateOfBirth,
                                              setOpenCalendar,
                                              setPatchDateDocument,
                                            }) => {
  const [valueDate, onChangeDate] = useState<any>(new Date);

  useEffect(() => {
    setPatchDateDocument && setPatchDateDocument({...patchDateDocument, completion_date: convertNewDate(valueDate)})
    setValueDate && setValueDate({value: convertNewDate(valueDate), id: idField})
    setValueDateOfBirth && setValueDateOfBirth({value: convertNewDate(valueDate), id: idField})
    String(valueDate) !== String(new Date) && setOpenCalendar({id: null, open: false})
  }, [valueDate])

  const closeCalendar = () => {
    setPatchDateDocument && setPatchDateDocument({...patchDateDocument, completion_date: '', id: null})
    setValueDate && setValueDate({value: convertNewDate(''), id: idField})
    setValueDateOfBirth && setValueDateOfBirth({value: convertNewDate(''), id: idField})
    setOpenCalendar({id: null, open: false})
  }

  return (
    <div className={styles.calendarWrapper} style={{transform: type === 'document' && 'translate(-90%, -100%)'}}>
      <img onClick={closeCalendar} className={styles.close} src={closeSvg} alt="close"/>
      <Calendar
        onChange={onChangeDate}
        value={valueDate}
        className={styles.calendar}
      />
    </div>
  )
}

export default DateCalendar;