import "./errorPopup.scss";

export type Props = {
    isOpen: boolean,
    text: string,
}

export const ErrorPopup = (props: Props) => {
  const { isOpen, text } = props;
  return (
    <section className={`popup ${isOpen ? "popup_opened" : ""}`}>
      <p className="popup__text">{text}</p>
    </section>
  );
};
