import {detailsCardType} from "../../../../../types/detailsCardTypes";
import {all, put, call, takeEvery, select} from "redux-saga/effects";
import {getDetails, getDetailsCardType} from "../../actions/diagramActions/getDetailsCard";
import {DELETE_DOCUMENT_SAGA, GET_DETAILS_CARD_ACTION} from "../../actionTypes";
import {api} from "../../../../../api/restClient";


const deleteApiDocument = (delObj) => {
  const {cardId, ordmId, docId} = delObj
  return api.delete(`v1/organigram/${ordmId}/cards/${cardId}/files/${docId}`)
    .catch((err) => console.log(err))
}

const getApiDetailsCard = (getObj) => {
  const {cardId, ordmId} = getObj
  return api.get(`v1/organigram/${ordmId}/cards/${cardId}/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}


function* getDet(getObj) {
  const details: detailsCardType = yield call(getApiDetailsCard, getObj)
  const getDetailsCardAction: getDetailsCardType = {
    type: GET_DETAILS_CARD_ACTION,
    payload: {
      card_id: getObj.cardId,
      ordinagram_id: getObj.ordmId,
      details: details,
      requestType: ""
    }
  }
  yield put(getDetails(getDetailsCardAction))
}

function* deleteDocumentAsync(action) {
  const deleteObj = {
    cardId: action.payload.card_id,
    ordmId: action.payload.ordinagram_id,
    docId: action.payload.document_id
  }
  const getObj = {cardId: action.payload.card_id, ordmId: action.payload.ordinagram_id}
  yield call(deleteApiDocument, deleteObj)
  yield call(getDet, getObj)
}

export function* deleteDocumentSagas() {
  yield takeEvery(DELETE_DOCUMENT_SAGA, deleteDocumentAsync)
}