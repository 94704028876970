import React, {FC, useEffect, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, convertFromHTML, ContentState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './comments.scss'
import htmlToDraft from 'html-to-draftjs';
import {Save, saveType} from "../../../save/save";
import {useTranslation} from "react-i18next";

type CommentsType = {
  openComments?: boolean
  comments: string
  className?: string
  objectPatchFormation?: (e, changeType: string) => void
}

const Comments: FC<CommentsType> = ({
                                      openComments,
                                      comments,
                                      className,
                                      objectPatchFormation,
                                    }) => {
  const [editorState, setStateValue] = useState(EditorState.createEmpty());
  const [textareaFocus, setTextareaFocus] = useState(false)
  const [oldValue, setOldValue] = useState(comments)
  const value = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  const [t] = useTranslation();

  useEffect(() => {
    setOldValue(comments)
    const html = `${comments}`;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setStateValue(editorState)
    }
  }, [comments, textareaFocus])

  const onEditorStateChange = (editorState) => {
    setStateValue(editorState)
  }

  const focusTextarea = (e) => {
    setTextareaFocus(true)
  }

  return (
    <div className={className}>
      <div style={{
        border: `2px solid ${textareaFocus ? '#0274ac' : className ? '#53a9d773' : className && textareaFocus ? '#0274ac' : 'transparent'}`,
        borderRadius: 8,
      }}>
        <Editor
          editorState={editorState}
          wrapperClassName={`comments-wrapper`}
          editorClassName="comments-editor"
          onEditorStateChange={onEditorStateChange}
          onFocus={(e) => focusTextarea(e)}
          readOnly={openComments && true}
          toolbarStyle={{
            display: className === 'card-comment' ? 'none' : textareaFocus ? 'flex' : 'none',
          }}
          editorStyle={{
            minHeight: textareaFocus && 80,
            height: textareaFocus ? 'auto' : 80,
            overflowY: comments?.length > 100 ? 'auto' : textareaFocus ? 'hidden' : 'clip',
          }}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'emoji', 'history'],
            inline: {
              inDropdown: true,
              className: 'wrapper-icons',
              monospace: {className: "d-none"},
              superscript: {className: "d-none"},
              subscript: {className: "d-none"},
            },
            blockType: {
              inDropdown: true,
              className: 'wrapper-icons',
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            },
            list: {
              inDropdown: true,
              className: 'wrapper-icons',
              options: ['unordered', 'ordered'],
            },
            textAlign: {
              inDropdown: true,
              className: 'wrapper-icons',
              options: ['left', 'center', 'right', 'justify'],
            },
            colorPicker: {
              className: 'wrapper-icons',
              component: undefined,
            },
            emoji: {
              className: 'wrapper-icons',
            },
          }}
        />
      </div>
      {className !== 'card-comment' && textareaFocus && <Save valueComment={value}
                                                              setTextareaFocus={setTextareaFocus}
                                                              objectPatchFormation={objectPatchFormation}
                                                              type={saveType.comments}
                                                              oldValueComments={oldValue}
                                                              text={t('ready_btn')}
      />}
    </div>
  )
}

export default Comments
