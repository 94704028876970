import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import reportWebVitals from "./app/reportWebVitals";
import {register} from './serviceWorker'

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
register();
