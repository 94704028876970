import { AuthForm } from "./parts";
import { handleRegister } from "../../api/authApi";
import { connector } from "./container";

const Register = () => {
  return (
    <AuthForm
      linkTo="/login"
      linkText="Уже есть аккаунт?"
      title="Регистрация"
      buttonText="Зарегистрироваться"
      handleAuthorization={handleRegister}
    />
  );
};

export default connector(Register);
