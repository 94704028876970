import React, {useState} from "react";
import styles from './filter.module.scss';
import {statuses} from "../menu-card/constant";
import {useDispatch, useSelector} from "react-redux";
import {appGlobalStore} from "../../store/root-reducer";
import {cardType, ordinagramType} from "../../types/types";
import {modalType} from "../../components/diagrams/Main";
import {getCardsAction} from "../../pages/home/redux-sagas/actions/diagramActions/getCards";
import {GET_CARDS_ACTION} from "../../pages/home/redux-sagas/actionTypes";
import {useTranslation} from "react-i18next";

const Filter = () => {
  const dispatch = useDispatch()
  const initialElements = useSelector(({homePage}: appGlobalStore) => homePage.cards)
  const connectLines = useSelector(({homePage}: appGlobalStore) => homePage.connectLines)
  const activeOrdinagramId = useSelector(({homePage}: appGlobalStore) => homePage.activeOrdinagramId)
  const [selectedColor, setSelectedColor] = useState<string>('')
  const [t] = useTranslation();

  const handleButtonFilter = (e, color, status) => {
    setSelectedColor(color)
    const newCards = initialElements.map((item: cardType) => {
      switch (status) {
        case 'without_status':
          return {...item, filterStatus: {value: status, opacity: item.status.status ? 0.3 : 1}}
        case 'is_ill':
          return {...item, filterStatus: {value: status, opacity: item.status.status === 'is_ill' ? 1 : 0.3}}
        case 'business_trip':
          return {...item, filterStatus: {value: status, opacity: item.status.status === 'business_trip' ? 1 : 0.3}}
        case 'vacation':
          return {...item, filterStatus: {value: status, opacity: item.status.status === 'vacation' ? 1 : 0.3}}
        case '':
          return {...item, filterStatus: {value: '', opacity: 1}}
        default:
          return {...item}
      }
    })
    initialElements.length !== 0 && dispatch(getCardsAction({
      type: GET_CARDS_ACTION,
      payload: {
        data: {
          cards: newCards,
          connection_lines: connectLines,
        },
        ordinagramId: Number(activeOrdinagramId)
      }
    }))
  }


  return (
    <div className={styles.filter}>
      <p className={`${styles.text} ${styles.text_choice}`}>{t('show_cards')}</p>
      <ul className={styles.list}>
        <li className={styles.card}>
          <p onClick={(e) => handleButtonFilter(e, '#ffffff', '')}
             className={styles.text}>
            <span className={`${styles.icon} ${styles.icon_black}`}/>
            {t('without_filter')}
          </p>
        </li>
        <li className={styles.card}>
          <p onClick={(e) => handleButtonFilter(e, '#ffffff','without_status')}
             className={styles.text}>
            <span className={`${styles.icon} ${styles.icon_brown}`}/>
            {t('without_status')}
          </p>
        </li>
        {statuses.map((item, index) => {
          const color = Object.keys(item)[0]
          const status = Object.values(item)[0]
          return (
            <li className={styles.card} key={`${index}_${item}`}>

              <p
                  onClick={(e) => handleButtonFilter(e, color, status)}
                  className={styles.text}
                  style={{borderColor: selectedColor === color && '#1D8BC5'}}
              >
                <span className={styles.icon} style={{background: color}} />
                {t(`${status}`)}
              </p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Filter
