import {
  GET_DETAILS_CARD_ACTION,
  PATCH_DETAILS_CARD_SAGA,
  PUT_DETAILS_CARD_SAGA,
  PUT_FIELDS_SAGA
} from "../../actionTypes";
import {detailsCardType} from "../../../../../types/detailsCardTypes";



export type getDetailsCardType = {
  type: typeof GET_DETAILS_CARD_ACTION | typeof PUT_DETAILS_CARD_SAGA | typeof PATCH_DETAILS_CARD_SAGA | typeof PUT_FIELDS_SAGA,
  payload: {
    card_id: string,
    ordinagram_id: number,
    details: detailsCardType,
    urlType?: "details" | "fields" | "documents" | ""
    requestType?: "PUT" | "PATCH" | ""
  }
}

export const getDetails = (data) => ({
  type: data.type,
  payload: data.payload,
})