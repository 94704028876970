import React, {useEffect, useState} from "react";
import './control-panel.scss'
import {
  ArrowDownSvg,
  FilterSvg,
  downloadSvg,
  menuSvg,
  plusSvg,
  printerSvg,
  shareSvg,
  userCheckSvg,
  ChevronLeft,
  UserCheckActive,
  deleteSvg,
} from "./images";
import CreateOrdinagram from "../../modals/create-orginagram/create-ordinagram";
import ChoiceOrdinagram from "../../modals/choice-ordinagram/choice-ordinagram";
import {useDispatch, useSelector} from "react-redux";
import {appGlobalStore} from "../../store/root-reducer";
import {ordinagramType} from "../../types/types";
import {GET_CARDS_SAGA, PREVIEW_PRINT_ACTION} from "../../pages/home/redux-sagas/actionTypes";
import Confirm from "../../modals/confirm/confirm";
import {previewPrintAction} from "../../pages/home/redux-sagas/actions/printAction/previewPrintAction";
import Filter from "../../modals/filter/filter";
import Share from "../../modals/share/share";
import { Loader } from '../../ui-kit'
import { LanguageSelect } from '../language-switcher'
import {useTranslation} from "react-i18next";

export const ControlPanel = (props) => {
  const {
    toggleLeftSidePanel,
    toggleRightSidePanel,
    isLeftPanelOpen,
    isRightPanelOpen,
    downloadDocument,
    handleShare,
    shareOpen,
    setShareOpen,
    fileForShare,
  } = props;

  const ordinagrams: ordinagramType[] = useSelector(({homePage}: appGlobalStore): ordinagramType[] => homePage.ordinagrams)
  const activeOrdinagramId: number = useSelector(({homePage}: appGlobalStore): number => homePage.activeOrdinagramId)
  const previewPrint = useSelector(({homePage}: appGlobalStore) => homePage.previewPrint)
  const [openModalChoice, setOpenModalChoice] = useState(false);
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [idRemovableOrdm, setIdRemovableOrdm] = useState<number>(0)
  const [activeOrdm, setActiveOrdm] = useState<ordinagramType>({} as ordinagramType)
  const dispatch = useDispatch()

  const [t] = useTranslation();

  const deleteType = {
    delete_ordm: t('deleting_an_ordinagram'),
    delete_card: t('deleting_card')
  }

  const shareStylePosition = {
    position: 'relative',
    top: 40,
    left: 30,
    display: shareOpen ? 'flex' : 'none',
  }

  const shareLoaderStyle = {
    position: 'relative',
    top: -4,
    left: '33%',
    height: 15,
    width: 0,
  }

  useEffect(() => {
    ordinagrams.length && activeOrdinagramId && activeOrdinagram()
  }, [activeOrdinagramId])

  const choiceOrdinagram = (actOrdm: ordinagramType): any => {
    setOpenModalChoice((open) => !open);
    dispatch({type: GET_CARDS_SAGA, payload: actOrdm.id})
  }


  const print = () => {
    const printContents = document.getElementById("print-div");
    const previewPanelContents = document.getElementById("preview-panel");
    previewPanelContents.style.display = 'none'
    printContents.style.background = '#ffffff'
    printContents.style.position = 'absolute'
    printContents.style.top = '0px'
    printContents.style.left = '0px'
    printContents.style.borderColor = 'transparent'
    window.print();
    previewPanelContents.style.display = 'flex'
    printContents.style.background = 'transparent'
    printContents.style.position = 'relative'
    printContents.style.borderColor = '#1D8BC5'
  }

  const previewZoom07 = (A3, A4, zoom) => {
    dispatch(previewPrintAction({
      type: PREVIEW_PRINT_ACTION, payload: {
        A3: A3 || false,
        A4: A4 || false,
        previewMode: zoom !== 1,
      }
    }))
    //@ts-ignore
    document.body.style.zoom = zoom
  }

  const activeOrdinagram = () => {
    const obj = ordinagrams.find((item: ordinagramType): boolean => item.id === activeOrdinagramId)
    setActiveOrdm(obj)
  }

  const showConfirm = (card: ordinagramType, ordmId) => {
    setIdRemovableOrdm(card?.id || ordmId)
    setOpenModalConfirm(true)
    setOpenModalChoice(false)
  }

  const onClickOpenModalChoice = () => {
    setOpenModalChoice((open) => !open);
    setOpenModalFilter(false)
  };

  const onClickOpenModalCreate = () => {
    setOpenModalCreate((open) => !open);
    setOpenModalChoice(false);
    setOpenModalFilter(false)
  };

  const onClickOpenModalFilter = () => {
    // выезжание
    setOpenModalFilter((open) => !open)
    setOpenModalChoice(false);
  }

  const leftPanelOpenClassName = `${isLeftPanelOpen && "padding-left"}`;
  const rightPanelOpenClassName = `${isRightPanelOpen && "padding-right"}`;


  return (
    <>
      {previewPrint.A3 || previewPrint.A4 ?
        <div id="preview-panel" className="previewPanel">
          <div className="d-flex align-items-center justify-content-center">
            <p onClick={() => previewZoom07(true, false, 0.55)}
               className="controlPanel__text controlPanel__text_preview 5"
               style={{color: previewPrint.A3 && '#1D8BC5'}}>A3</p>
            <p onClick={() => previewZoom07(false, true, 0.55)}
               className="controlPanel__text controlPanel__text_preview"
               style={{color: previewPrint.A4 && '#1D8BC5'}}>A4</p>
          </div>
          <div>

            <button onClick={() => previewZoom07(false, false, 1)}
                    className="mr-25 confirmBtn confirmBtn_cancel">{t('cancel_btn')}
            </button>
            <button onClick={print} className="confirmBtn">{t('print_btn')}</button>
          </div>
        </div>
        :
        <div className={`controlPanel ${leftPanelOpenClassName} ${rightPanelOpenClassName}`}>
          <div className="controlPanel__item mr-10" onClick={toggleLeftSidePanel}>
            {isLeftPanelOpen ? <ChevronLeft/> : <img src={menuSvg} alt="menu"/>}
          </div>
          <div className="relative">
            <div className="controlPanel__item mr-10">
              {ordinagrams?.length === 0 && activeOrdm !== undefined ? (
                <div onClick={onClickOpenModalCreate} className="d-flex align-items-center">
                  <p className="controlPanel__text">{t('create_new')}</p>
                  <img className="p-10 cup" src={plusSvg} alt="add"/>
                </div>
              ) : (
                <div onClick={onClickOpenModalChoice} className="d-flex align-items-center">
                  <p className="controlPanel__text"
                     style={{color: openModalChoice && "#1D8BC5"}}>
                    {activeOrdm?.title !== undefined ? `${activeOrdm?.title}` : ''}
                  </p>
                  <ArrowDownSvg className="ml-10 cup" openModalChoice={openModalChoice}/>
                </div>)}
            </div>
            {openModalCreate && (<CreateOrdinagram ordinagrams={ordinagrams}
                                                   open={openModalCreate}
                                                   setOpenModalCreate={setOpenModalCreate}
            />)}
            {openModalChoice && (<ChoiceOrdinagram ordinagrams={ordinagrams}
                                                   choiceOrdinagram={choiceOrdinagram}
                                                   onClickOpenModalCreate={onClickOpenModalCreate}
                                                   showConfirm={showConfirm}
            />)}

            {openModalConfirm && <Confirm open={openModalConfirm}
                                          setOpenModalConfirm={setOpenModalConfirm}
                                          idRemovableOrdm={idRemovableOrdm}
                                          type={deleteType.delete_ordm}/>}
          </div>
          <div className="controlPanel__item">
            <img onClick={() => {
              previewZoom07(true, false, 0.57);
              isLeftPanelOpen && toggleLeftSidePanel();
              isRightPanelOpen && toggleRightSidePanel();
            }}
                 className="mr-25" src={printerSvg} alt="printer"/>
            <div onClick={() => downloadDocument('download', `${activeOrdm?.title || 'empty-file'}.pdf`)}>
              <img className="mr-25" src={downloadSvg} alt="download"/>
            </div>
            <div onClick={handleShare}>
              <img src={shareSvg} alt="share"/>
            </div>
            {!fileForShare && shareOpen ?
                <div className='share-loader-container'>
                  <Loader
                      style={shareLoaderStyle}
                  />
                </div> :
                <Share
                    setShareOpen={setShareOpen}
                    urlDocument={fileForShare}
                    fileName={`${activeOrdm?.title || 'empty-file'}.pdf`}
                    style={shareStylePosition}
                />

            }
          </div>
          <div className="controlPanel__item ml-auto">
            <div onClick={toggleRightSidePanel}>
              {isRightPanelOpen ? <div className='mr-25'><UserCheckActive/></div>
                : <img className="mr-25" src={userCheckSvg} alt="user check"/>}
            </div>
            {/*<img className="mr-25" src={briefcaseSvg} alt="briefcase"/>*/}
            <div onClick={onClickOpenModalFilter} className="relative">
              <FilterSvg openModalFilter={openModalFilter}/>
              {openModalFilter && <Filter/>}
            </div>
          </div>
          <div className="controlPanel__item ml-10">
            <img onClick={() => showConfirm(null, activeOrdinagramId)} src={deleteSvg} alt="del"/>
          </div>
        </div>
      }

    </>
  );
}

