import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as actions from "../pages/authorization/redux-sagas/actions";
import * as selectors from "../pages/authorization/redux-sagas/selectors";
import { setUserEmail, setFullUser } from "../pages/home/redux-sagas/actions";

const mapStateToProps = createStructuredSelector({
  authorization: selectors.authPageStateSelector,
});

const mapDispatchToProps = {
  loggedIn: actions.loggedIn,
  setSecretLink: actions.setSecretLink,
  setLoadingTrue: actions.setLoadingTrue,
  setLoadingFalse: actions.setLoadingFalse,
  setUserEmail,
  setFullUser,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
