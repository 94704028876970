import {all, put, call, takeEvery, select} from "redux-saga/effects";
import {api} from "../../../../../api/restClient";
import {DISCONNECT_CONNECTION_LINE_SAGA, GET_CARDS_ACTION} from "../../actionTypes";
import {getCardsAction} from "../../actions/diagramActions/getCards";

const deleteDisconnectConnectionLine = (delObj) => {
  const {cardId, ordmId} = delObj
  return api.delete(`v1/organigram/${ordmId}/cards/${cardId}/disconnect_connection_line/`)
    .catch((err) => console.log(err))
}

const getApiCards = (id) => {
  return api.get(`v1/organigram/${id}/cards/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}


function* disconnectConnectionLineAsync (action) {
  const delObj = {cardId: action.payload.card_id, ordmId: action.payload.ordinagram_id}
  yield call(deleteDisconnectConnectionLine, delObj)
  const response = yield call(getApiCards, action.payload.ordinagram_id)
  yield put(getCardsAction({
    type: GET_CARDS_ACTION,
    payload: {data: response, ordinagramId: action.payload.ordinagram_id}}))
}

export function* disconnectConnectionLineSagas () {
  yield takeEvery(DISCONNECT_CONNECTION_LINE_SAGA, disconnectConnectionLineAsync)
}