import React from 'react';
import FileViewer from 'react-file-viewer';
import {CustomErrorComponent} from "./error-component";

import "./style.scss";
import {downloadSvg} from "../../components/control-panel/images";
export const FileViewPage = () => {
    const document = localStorage.getItem('document');
    const fileName = JSON.parse(document)?.name || ''
    const filePath = JSON.parse(document)?.file || ''
    const fileType = JSON.parse(document)?.file?.split('.').pop();

    const onError = (err) => {
        console.log('err: ', err)
    }
    return (
        <div className='file_container'>
            <div className='file_view_header'>
                <h1>{fileName}</h1>
                <a href={filePath} download={fileName} className='download_btn'>
                    <img src={downloadSvg} alt="download"/>
                </a>
            </div>


            <FileViewer
                allowFullScreen={true}
                fileType={fileType}
                filePath={filePath}
                errorComponent={CustomErrorComponent}
                onError={onError}
            />
        </div>

    );
    }
