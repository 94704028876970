import React, {FC} from "react";

type FilterSvgType = {
  openModalFilter: boolean
}

const FilterSvg: FC<FilterSvgType> = ({openModalFilter}) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke={openModalFilter ? '#1D8BC5' : "black"} strokeOpacity="0.5" strokeWidth="1.5"
            strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default FilterSvg;