import {PATCH_CARD_ACTION} from "../../actionTypes";

export type patchCardTypeAction = {
  type: typeof PATCH_CARD_ACTION,
  payload: {
    card_id: string,
    ordinagram_id: number,
    property: any
    type: string
  }
}


export const patchCardAction = (data:patchCardTypeAction) => ({
  type: data.type,
  payload: data.payload
})