import { useHistory } from "react-router-dom";
import { Form, InputEmail } from "./parts";
import { Button } from "../../ui-kit";
import { useFormWithValidation } from "../../utils/validation";
import { sendEmail } from "../../api/authApi";
import { connector } from "./container";

const RestorePasswordForm = (props) => {
  const { setSecretLink, setEmail } = props;
  const history = useHistory();

  const {
    values,
    handleChange,
    errors,
    isValid,
    // resetForm,
  } = useFormWithValidation();

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(values).then((res) => {
      setEmail(values.email);
      setSecretLink(res.data);
      localStorage.setItem('secret', JSON.stringify(res.data))
      history.push("/restore-password-info");
    });
  };

  return (
    <Form
      linkTo="/login"
      linkText="Вернуться на страницу входа"
      title="Восстановление пароля"
      onSubmit={handleSubmit}
    >
      <p className="authForm__text">На какую почту вы регистрировались?</p>
      <InputEmail onChange={handleChange} errorText={errors.email} />
      <Button
        type="submit"
        text="Продолжить"
        className="authForm__button"
        disabled={!isValid}
      />
    </Form>
  );
};

export default connector(RestorePasswordForm);
