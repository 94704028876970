import {produce} from 'immer'
import {initStateType} from "../../reducer";
import {WritableDraft} from "immer/dist/types/types-external";
import {ordinagramType} from "../../../../../types/types";
import {getCardsActionType} from "../../actions/diagramActions/getCards";
import {getOrdinagramsActiontype} from "../../actions/diagramActions/getOrdinagrams";

export const getOrdmReducer = (state: initStateType, action: getOrdinagramsActiontype) =>
  produce(state, (draft: WritableDraft<initStateType>): void => {
    const newOrdm: ordinagramType[] = action.payload.map((ordm: ordinagramType): ordinagramType => {
      return {
        id: ordm.id,
        title: ordm.title,
        type: ordm.type,
        connect_line: ordm.connect_line,
        base_color: ordm.base_color,
        zoomOnScroll: ordm.zoomOnScroll !== undefined ? ordm.zoomOnScroll : true,
        isDraggableCard: ordm.isDraggableCard !== undefined ? ordm.isDraggableCard : true,
      }
    })

    draft.ordinagrams = newOrdm
  })
