import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {getDetails, getDetailsCardType} from "../../actions/diagramActions/getDetailsCard";
import {api} from "../../../../../api/restClient";
import {GET_CARDS_ACTION, GET_DETAILS_CARD_ACTION, PUT_DETAILS_CARD_SAGA} from "../../actionTypes";
import {detailsCardType} from "../../../../../types/detailsCardTypes";
import {getCardsAction} from "../../actions/diagramActions/getCards";


const putApiDetails = (putObj) => {
  const {cardId, ordmId, params} = putObj
  // return api.put(`v1/organigram/${ordmId}/cards/${cardId}/fields/`, params.fields)
  return api.put(`v1/organigram/${ordmId}/cards/${cardId}/`, params)
    .catch((err) => console.log(err))
}

const getApiCards = (ordmId) => {
  return api.get(`v1/organigram/${ordmId}/cards/`)
    .catch((err) => console.log(err))
}


function* putDetailsAsync(action: getDetailsCardType) { // PUT
  const putObj = {
    cardId: action.payload.card_id,
    ordmId: action.payload.ordinagram_id,
    params: action.payload.details
  }
  const response = yield call(putApiDetails, putObj)
  const details: detailsCardType = yield select((state) => state.homePage.detailsCard)
  const newDetails: detailsCardType = {
    ...response.data,
    documents: details.documents,
    fields: details.fields
  }
  yield put(getDetails({
    type: GET_DETAILS_CARD_ACTION,
    payload: {
      card_id: putObj.cardId,
      ordinagram_id: putObj.ordmId,
      details: newDetails
    }
  } as getDetailsCardType))
}


export function* putDetailsSagas() {
  yield takeEvery(PUT_DETAILS_CARD_SAGA, putDetailsAsync)
}