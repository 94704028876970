import { connector } from "./container";
import { AuthForm } from "./parts";
import { CustomLink } from "../../ui-kit";
import { handleLogin } from "../../api/authApi";

const Login = () => {
  return (
    <AuthForm
      linkTo="/register"
      linkText="Нет аккаунта?"
      title="Вход"
      buttonText="Войти"
      handleAuthorization={handleLogin}
    >
      <CustomLink
        to="/restore-password"
        text="Забыли пароль?"
        className="authForm__forgotPass"
        textClassName="forgotPass_text"
      />
    </AuthForm>
  );
};

export default connector(Login);
