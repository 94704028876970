import { useState } from "react";
import "./filterSelect.scss";
import { Up } from "./images/up";
import { Down } from "./images/down";
import {useTranslation} from "react-i18next";

export const FilterSelect = ({ onChange, value }) => {
  const [opened, setOpened] = useState(false);
  const [t] = useTranslation();

  const handleOpenSelect = () => {
    setOpened((opened) => !opened);
  };

  const handleSelectOption = (e) => {
    onChange(e.target.outerText);
  };

  const selectedClass = `${opened ? "selected selected-opened" : "selected"}`;
  const optionClass = `${opened ? "option option-opened" : "option"}`;

  return (
    <div className="single-select" onClick={handleOpenSelect}>
      <p className={selectedClass}>
        {value}
        {opened ? <Up /> : <Down />}
      </p>
      <ul className={optionClass}>
        <li className="value" onClick={handleSelectOption}>
          {t('by_surname')}
        </li>
        <li className="value" onClick={handleSelectOption}>
          {t('by_callsign')}
        </li>
        <li className="value" onClick={handleSelectOption}>
          {t('by_department')}
        </li>
      </ul>
    </div>
  );
};
