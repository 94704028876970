import {all, put, call, takeEvery, select} from "redux-saga/effects";
import {CREATE_ORDINAGRAM_ACTION, GET_CARDS_SAGA, POST_ORDINAGRAM_SAGA} from "../../actionTypes";
import {api} from "../../../../../api/restClient";
import {createOrdinagram} from "../../actions/diagramActions/createOrdinagram";

const postApiOrdm = (obj) => {
  return api.post('v1/organigram/', obj)
    .catch((err) => console.log(err))
}
const getApiOrdm = () => {
  return api.get('v1/organigram/')
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

function* createNewOrdAsync(action) {
  try {
    yield call(postApiOrdm, action.payload)
    const data = yield call(getApiOrdm)
    yield put(createOrdinagram({type: CREATE_ORDINAGRAM_ACTION, payload: data[data.length - 1]}))
    yield put({type: GET_CARDS_SAGA, payload: data[data.length - 1].id})
  } catch (err) {
    console.log(err)
  }
}

export function* createNewOrdSagas() {
  yield takeEvery(POST_ORDINAGRAM_SAGA, createNewOrdAsync)
}