import React, {FC, useState} from "react";
import './create-ordinagram.scss'
import Modal from '@material-ui/core/Modal';
import {Save, saveType} from "../save/save";
import {arrow1Svg, arrow2Svg, orgmLeftRightSvg, orgmTopBottomSvg} from "./images";
import {useDispatch, useSelector} from "react-redux";
import {ordinagramType, postOrdinagramType} from "../../types/types";
import {POST_ORDINAGRAM_SAGA} from "../../pages/home/redux-sagas/actionTypes";
import {useTranslation} from "react-i18next";

const lines = [
  {id: 1, img: arrow1Svg, connectLine: 'default'},
  {id: 2, img: arrow2Svg, connectLine: 'smoothstep'},
]
const diagram = [
  {id: 1, img: orgmTopBottomSvg, type: 'top-bottom'},
  // {id: 2, img: orgmLeftRightSvg, type: 'left-right'},
]
const colors = [
  {id: 1, color: '#878F93'},
  {id: 2, color: '#C4CCD7'},
  {id: 3, color: '#53A9D7'},
]
type CreateOrdType = {
  open: boolean
  ordinagrams: ordinagramType[]
  setOpenModalCreate: (boolean) => void,
}

const CreateOrdinagram: FC<CreateOrdType> = ({
                                               open = false,
                                               ordinagrams,
                                               setOpenModalCreate,
}) => {

  const [t] = useTranslation();

  const [newOrdm, setNewOrdm] = useState<postOrdinagramType>({
    order_id: 0,
    title: '',
    type: 'top-bottom',
    connect_line: 'default',
    base_color: '#878F93'
  } )
  const [selectedDiagram, setSelectedDiagram] = useState(1)
  const [selectedLine, setSelectedLine] = useState(1)
  const [selectedColor, setSelectedColor] = useState(1)
  const dispatch = useDispatch()

  const handleClose = () => {
    setOpenModalCreate(false);
  };
  const createOrdm = () => {
    newOrdm.title !== '' && dispatch({type: POST_ORDINAGRAM_SAGA, payload: newOrdm})
    newOrdm.title !== '' && setOpenModalCreate(false)
  }

  const onTitleOrdm = (e) => {
    setNewOrdm({...newOrdm, title: e.target.value, order_id: ordinagrams.length + 1})
  }

  const onDiagramSelection = (item) => {
    setNewOrdm({...newOrdm, type: item.type, order_id: ordinagrams.length + 1})
    setSelectedDiagram(item.id)
  }

  const onLineSelection = (item) => {
    setNewOrdm({...newOrdm, connect_line: item.connectLine, order_id: ordinagrams.length + 1})
    setSelectedLine(item.id)
  }

  const onColorSelection = (item) => {
    setNewOrdm({...newOrdm, base_color: item.color})
    setSelectedColor(item.id)
  }

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className="modal crt-orgm">
            <input onChange={(e) => onTitleOrdm(e)} className="crt-orgm__input" type="text"
                   placeholder={t('organization_name')}/>
          <p className="crt-orgm__text" style={{borderTop: '1px solid #dcd8d8', paddingTop: 12}}>
            {t('select_type_of_organigram')}
          </p>
          <div className="d-flex align-items-center" style={{padding: 8}}>
            {diagram.map(item => (
              <button onClick={() => onDiagramSelection(item)} key={item.id}
                      className={`cup mr-10 crt-orgm__line ${selectedDiagram === item.id && 'crt-orgm__line_active'}`}>
                <img src={item.img} alt="top-bottom"/>
              </button>
            ))}
          </div>
          <p className="crt-orgm__text" style={{padding: '0 12'}}>{t('select_connecting_lines')}</p>
          <div className="d-flex align-items-center" style={{padding: 8}}>
            {lines.map(item => (
              <button onClick={() => onLineSelection(item)} key={item.id}
                      className={`cup mr-10 crt-orgm__line ${selectedLine === item.id && 'crt-orgm__line_active'}`}>
                <img src={item.img} alt="arrow"/>
              </button>
            ))}
          </div>
          <div className="d-flex mt-15">
            {colors.map(item => (
              <div className="mr-10 cup" key={item.id}
                   onClick={() => onColorSelection(item)}
                   style={{
                     backgroundColor: `${item.color}`,
                     border: selectedColor === item.id ? '2px solid #1D8BC5' : '1.5px solid transparent',
                     borderRadius: '50%',
                     width: 20, height: 20
                   }}
              />))}
          </div>
          <Save className="create-orgm" newOrdm={newOrdm} createOrdm={createOrdm} handleClose={handleClose} type={saveType.createOrdinagram} text={t('сreate_btn')}/>
        </div>
      </Modal>
    </div>
  )
}

export default CreateOrdinagram;
