
export const phoneFormatting = (phone) => {
  var lenPhone = phone.length;
  var tt=phone.split('');
  if(lenPhone == 12){
    tt.splice(2,"", " (");
    tt.splice(6,"", ") ");
    tt.splice(10,"", "-");
    tt.splice(13,"", "-");
  }else if(lenPhone == 13){
    tt.splice(3,"", " (");
    tt.splice(7,"", ") ");
    tt.splice(11,"", "-");
    tt.splice(14,"", "-");
  }
  return  tt.join('')
}