import produce from "immer";
import {initStateType} from "../../reducer";
import {WritableDraft} from "immer/dist/types/types-external";

export const createOrdmReducer = (state: initStateType, action) =>
  produce(state, (draft: WritableDraft<initStateType>) => {
    const newItems = state.ordinagrams?.concat(action.payload)
    draft.ordinagrams = newItems
    draft.activeOrdinagramId = action.payload.id
  })
