export  const indicatorLabelColor = (daysLeft) => {
  if (daysLeft && daysLeft[daysLeft.length - 2] === 'д' &&
    Number(daysLeft.substr(0, 2)) <= 0) {
    return 0
  } else if (
    (daysLeft && daysLeft[daysLeft.length - 2] === 'д' &&
      Number(daysLeft.substr(0, 2)) > 0 &&
      Number(daysLeft.substr(0, 2)) < 31)
    ||
    (daysLeft &&
      daysLeft[daysLeft.length - 2] === 'м' &&
      Number(daysLeft.substr(0, 2)) <= 2)
  ) {
    return 1
  }
}