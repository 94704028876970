import { produce } from "immer";

export const setLoadingTrueReducer = (state) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

  export const setLoadingFalseReducer = (state) =>
  produce(state, (draft) => {
    draft.loading = false;
  });