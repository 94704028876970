import {api} from "../../../../../api/restClient";
import {all, put, call, select, takeEvery} from "redux-saga/effects";
import {GET_CARDS_SAGA, GET_ORDINAGRAM_SAGA, GET_ORDINAGRAMS_ACTION} from "../../actionTypes";
import {ordinagramType} from "../../../../../types/types";

const getApiOrdm = () => {
  return api.get('v1/organigram/')
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

function* getOrdmsAsync(action) {
  try {
    const {loggedIn} = yield select(state => state.authorization)
    const data:ordinagramType[] = yield call(getApiOrdm)
    yield put({type: GET_ORDINAGRAMS_ACTION, payload: data})
    const a = yield select()
    yield put({type: GET_CARDS_SAGA, payload: data[0].id})
  } catch (err) {
    console.log(err)
  }
}


export function* getOrgmsSagas() {
  // yield takeEvery(REQUEST_ORDINAGRAMS, requestOrdmsAsync)
  yield takeEvery(GET_ORDINAGRAM_SAGA, getOrdmsAsync)
  // yield all([
  //   //@ts-ignore
  //   call(getOrdmsAsync),
  // ])
}
