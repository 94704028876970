import React from 'react';
import '../styles/global.scss'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from '../store/store'
import Router from './router'

import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'

function App() {
  return (
      <I18nextProvider i18n={i18n}>
          <Provider store={store}>
              <BrowserRouter>
                  <Router />
              </BrowserRouter>
          </Provider>
      </I18nextProvider>
  );
}

export default App;
