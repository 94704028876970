import React, {FC, useEffect, useState} from "react";
import PhoneInput from 'react-phone-number-input'
import styles from './personal-data.module.scss';
import plusSquareSvg from '../../images/plus-square.svg';
import {detailsCardType, FieldsType} from "../../../../types/detailsCardTypes";
import {Save, saveType} from "../../../save/save"
import 'react-calendar/dist/Calendar.css';
import DateCalendar from "../../../../utils/calendar/calendar";
import 'react-phone-number-input/style.css';
import './phoneInput.scss';
import {useDispatch} from "react-redux";
import {
  DELETE_FIELD_SAGA,
  GET_DETAILS_CARD_ACTION,
  PUT_FIELDS_SAGA,
} from "../../../../pages/home/redux-sagas/actionTypes";
import {
  calendarSvg,
  date_of_birthSvg,
  deleteSvg,
  departamentSvg,
  mailSvg,
  mapPinSvg,
  phoneSvg,
  textSvg
} from "../../images";
import ValueInput from "../../modals/value-inputs/value-input";
import {getDetailsCardType} from "../../../../pages/home/redux-sagas/actions/diagramActions/getDetailsCard";
import {phoneFormatting} from "../../../../utils/phoneFormatting";
import {changeDateFormat} from "../../../../utils/convertDateYYYY-MM-DD";
import {useTranslation} from "react-i18next";

type PersonalDataType = {
  details: detailsCardType
}

const PersonalData: FC<PersonalDataType> = ({
                                              details,
                                            }) => {
  const dispatch = useDispatch();
  const [redactor, setRedactor] = useState(false)
  const [openCalendar, setOpenCalendar] = useState({id: '', open: false})
  const [valueDateOfBirth, setValueDateOfBirth] = useState({value: '', id: null})
  const [valueDate, setValueDate] = useState({value: '', id: null})
  const [toggleAdd, setToggleAdd] = useState(false)
  const [oldDetails, setOldDetails] = useState<detailsCardType>(details)
  const [changeDetails, setChangeDetails] = useState<detailsCardType>(details)
  const [newPatchField] = useState<FieldsType>({
    icon: null,
    order_id: null,
    title: "Опция",
    value: "",
    type: "text"
  })

  const [t] = useTranslation();

  useEffect(() => setChangeDetails(details), [details])

  useEffect(() => {
    valueDateOfBirth && newFields(valueDateOfBirth.value, valueDateOfBirth.id, "date_of_birth")
  }, [valueDateOfBirth.value])

  useEffect(() => {
    valueDate && newFields(valueDate.value, valueDate.id, "date")
  }, [valueDate.value])

  const newFields = (value, idField, type: "department" | "date_of_birth" | "text" | "date" | "phone" | "email" | "address") => {
    const changeFields = details.fields?.map((item: FieldsType, index) => {
      if (item.type === type && item.id === idField) {
        switch (type) {
          case "department":
            return {...item, value: value}
          case "date_of_birth":
            return {...item, value: value}
          case "phone":
            return {...item, value: value}
          case "email":
            return {...item, value: value}
          case "date":
            return {...item, value: value}
          case "address":
            return {...item, value: value}
          case "text":
            return {...item, value: value}
        }
      }
      return {...item}
    })
    setChangeDetails({...details, fields: changeFields})
    dispatch({
      type: GET_DETAILS_CARD_ACTION,
      payload: {
        card_id: details.card_id,
        ordinagram_id: details.ordinagram_id,
        details: {...details, fields: changeFields},
      }
    } as getDetailsCardType)
  }

  const handleCalendar = (idField) => {
    setOpenCalendar({id: idField, open: true})
  }

  const handlePhone = (value, idField) => {
    setOpenCalendar({id: null, open: false})
    newFields(value, idField, "phone")
  }

  const handleEmail = (e, idField) => {
    setOpenCalendar({id: null, open: false})
    newFields(e.target.value, idField, "email")
  }

  const handleAddress = (e, idField) => {
    setOpenCalendar({id: null, open: false})
    newFields(e.target.value, idField, "address")
  }

  const handleDepartament = (e, idField) => {
    setOpenCalendar({id: null, open: false})
    newFields(e.target.value, idField, "department")
  }

  const handleText = (e, idField) => {
    setOpenCalendar({id: null, open: false})
    newFields(e.target.value, idField, "text")
  }

  const handleRegactor = () => {
    setOldDetails(details)
    setOpenCalendar({id: null, open: false})
    setRedactor(true)
  }

  const handleAdd = () => {
    setToggleAdd(true)
    const newDetails: detailsCardType = {
      ...details,
      fields: [...details.fields, {...newPatchField, order_id: details.fields.length + 1}]
    }
    setChangeDetails(newDetails)
    !toggleAdd && dispatch({
      type: GET_DETAILS_CARD_ACTION,
      payload: {
        card_id: details.card_id,
        ordinagram_id: details.ordinagram_id,
        details: newDetails,
      }
    } as getDetailsCardType)
  }


  const closeRedactor = () => {
    setRedactor(false)
    setChangeDetails(oldDetails)
    dispatch({
      type: GET_DETAILS_CARD_ACTION,
      payload: {
        card_id: details.card_id,
        ordinagram_id: details.ordinagram_id,
        details: oldDetails,
      }
    })
    setToggleAdd(false)
  }

  const onSubmitFields = (e) => {
    e.preventDefault();
    const newFields = changeDetails.fields.map((item: FieldsType) => {
      return {
        ...item, icon: null
      }
    })

    dispatch({
      type: PUT_FIELDS_SAGA,
      payload: {
        card_id: details.card_id,
        ordinagram_id: details.ordinagram_id,
        details: {...changeDetails, fields: newFields},
        urlType: "fields",
      }
    } as getDetailsCardType)
    setRedactor(false)
    setToggleAdd(false)
  }

  const deleteField = (idField) => {
    dispatch({
      type: DELETE_FIELD_SAGA,
      payload: {
        card_id: details.card_id,
        ordinagram_id: details.ordinagram_id,
        field_id: idField
      }
    })
  }

  const displayedInput = (type: "department" | "date_of_birth" | "text" | "date" | "phone" | "email" | "address", field: FieldsType) => {
    switch (type) {
      case 'department':
        return (
          <input onChange={(e) => handleDepartament(e, field.id)}
                 type="text"
                 placeholder={t('department')}
                 className={styles.valueInput}
                 style={{border: 'none'}}
                 defaultValue={field.value}
          />
        )
      case 'date_of_birth':
        return (
          <>
            <input onClick={() => handleCalendar(field.id)}
                   type="text"
                   placeholder="01.07.1984"
                   readOnly
                   className={styles.valueInput}
                   style={{border: 'none'}}
                   value={field.value}
            />
            {Number(openCalendar.id) === field.id && openCalendar.open && <DateCalendar idField={field.id}
                                                                                        setOpenCalendar={setOpenCalendar}
                                                                                        setValueDateOfBirth={setValueDateOfBirth}
            />}
          </>
        )
      case 'phone':
        return (
          <PhoneInput onChange={(value) => handlePhone(value, field.id)}
                      placeholder="+7 999 123-45-67"
                      value={field.value}
                      type="tel"
                      className={styles.valueInput}
                      autoComplete="off"
          />
        )
      case 'email':
        return (
          <input onChange={(e) => handleEmail(e, field.id)}
                 className={styles.valueInput}
                 style={{border: 'none'}}
                 type="email"
                 autoComplete="off"
                 defaultValue={field.value}
                 placeholder="ivanov@gmail.com"/>
        )
      case 'text':
        return (
          <input onChange={e => handleText(e, field.id)}
                 type="text"
                 placeholder="Введите текст"
                 className={styles.valueInput}
                 style={{border: 'none'}}
                 defaultValue={field.value}
          />
        )
      case "date":
        return (
          <>
            <input onClick={() => handleCalendar(field.id)}
                   type="text"
                   placeholder="01.07.1984"
                   readOnly
                   className={styles.valueInput}
                   style={{border: 'none'}}
                   value={field.value}
            />
            {Number(openCalendar.id) === field.id && openCalendar.open && <DateCalendar idField={field.id}
                                                                                        setOpenCalendar={setOpenCalendar}
                                                                                        setValueDate={setValueDate}
            />}
          </>
        )
      case "address":
        return (
          <input onChange={(e) => handleAddress(e, field.id)}
                 type="text"
                 placeholder="Введите текст"
                 className={styles.valueInput}
                 defaultValue={field.value}
                 style={{border: 'none'}}
          />
        )
    }
  }


  const valueText = (type: "department" | "date_of_birth" | "text" | "date" | "phone" | "email" | "address", field: FieldsType) => {
    switch (type) {
      case 'department':
        return (
          <input type="text"
                 defaultValue={field.value}
                 className={`${styles.valueInput} ${styles.valueInput_empty}`}
                 style={{color: '#373530'}}
                 disabled/>
        )
      case 'date_of_birth':
        return (
          <input type="text"
                 defaultValue={changeDateFormat(field.value)}
                 className={`${styles.valueInput} ${styles.valueInput_empty}`}
                 style={{color: '#373530'}}
                 disabled/>
        )
      case 'phone':
        return (
          <a className={`${styles.valueInput} ${styles.valueInput_empty}`}
             href={`tel:${field.value}`}
             style={{color: '#373530'}}
          >
            {phoneFormatting(field.value)}
          </a>
        )
      case "email":
        return (
          <a className={`${styles.valueInput} ${styles.valueInput_empty}`}
             style={{cursor: "pointer", color: '#373530'}}
             href={`mailto:${field.value}?body=Добрый%20день!%0A%0A%0A%0A%0A`}>{field.value}</a>
        )
      case 'text':
        return (
          <input type="text"
                 defaultValue={field.value}
                 className={`${styles.valueInput} ${styles.valueInput_empty}`}
                 style={{color: '#373530'}}
                 disabled/>
        )
      case "date":
        return (
          <input type="text"
                 defaultValue={field.value}
                 className={`${styles.valueInput} ${styles.valueInput_empty}`}
                 style={{color: '#373530'}}
                 disabled/>
        )
      case "address":
        return (
          <input type="text"
                 defaultValue={field.value}
                 className={`${styles.valueInput} ${styles.valueInput_empty}`}
                 style={{color: '#373530'}}
                 disabled/>
        )
    }
  }

  const renderIcon = (type: "department" | "date_of_birth" | "text" | "date" | "phone" | "email" | "address") => {
    switch (type) {
      case "department":
        return departamentSvg
      case "date_of_birth":
        return date_of_birthSvg
      case "text":
        return textSvg
      case "date":
        return calendarSvg
      case "phone":
        return phoneSvg
      case "email":
        return mailSvg
      case "address":
        return mapPinSvg
    }
  }

  return (
    <div>
      <form>
        {details.fields?.map((field: FieldsType) => (
          <div key={field.id} className={`${styles.field} d-flex align-items-center mb-5`}>
            <img src={field?.type ? renderIcon(field?.type) : textSvg} alt="" style={{width: 16, height: 16}}/>
            <p className={styles.title}>{t(`${field.type}`)}</p>
            <div style={{position: "relative"}}>
              {
                toggleAdd && !field.id ? <ValueInput field={field}
                                                     card_id={details.card_id}
                                                     ordinagram_id={details.ordinagram_id}
                                                     setChangeDetails={setChangeDetails}
                                                     changeDetails={changeDetails}/>
                  : redactor ?
                  <div style={{position: 'relative'}}>
                    {displayedInput(field.type, field)}
                  </div>
                  : (field.value.length === 0. ?
                      <input className={`${styles.valueInput} ${styles.valueInput_empty}`}
                             readOnly
                             value={t('empty_input_placeholder') as string}
                             style={{border: 'none', background: "white"}}/>
                      :
                      valueText(field.type, field)
                  )
              }
            </div>
            {redactor && !openCalendar.open && field.type !== 'department' && <img onClick={() => deleteField(field.id)}
                                                                                   className={styles.delete}
                                                                                   src={deleteSvg} alt="delete"/>
            }

          </div>
        ))}

      </form>
      {
        redactor ? (
          <div className="d-flex align-items-center mb-5">
            <img src={plusSquareSvg} alt="" style={{width: 16, height: 16}}/>
            <p onClick={handleAdd}
               className={`${styles.title} ${styles.title_action}`}>{t('add_btn')}</p>
          </div>
        ) : (
          <div onClick={handleRegactor}
               className="d-flex align-items-center mb-5"
               style={{width: 'fit-content'}}>
            <img src={plusSquareSvg} alt="" style={{width: 16, height: 16}}/>
            <p className={`${styles.title} ${styles.title_action}`}>{t('edit_btn')}</p>
          </div>
        )
      }
      {redactor && <Save
        type={saveType.personalData}
        onSubmitFields={onSubmitFields}
        setRedactor={setRedactor}
        closeRedactor={closeRedactor}
        text={t('ready_btn')}
      />}
    </div>

  )
}

export default PersonalData;
