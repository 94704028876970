import React from 'react';
import { useTranslation } from 'react-i18next'
import './styles.scss'

export const LanguageSelect = ({ color = false }) => {
    const [t, i18n] = useTranslation();
    const languages = ['ru', 'en'];
    const changeLanguage = (lang) => i18n.changeLanguage(lang);

    return (
        <div className={color ? 'language-select-2' : 'language-select'}>
            {languages.map((lang) => (
                <span key={lang}>
          <input type='radio' id={lang}
                 name='languages' value={lang} checked={i18n.language === lang}
                 onChange={() => changeLanguage(lang)}
          />
          <label className={color ? 'lang-label-2' : 'lang-label'} htmlFor={lang}>{`${lang === 'ru' ? 'Russian' : 'English'}`}</label>
        </span>
            ))}
        </div>
    )
};
