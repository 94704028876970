import {PATCH_DETAILS_ACTION} from "../../actionTypes";


export type patchDetailsActionType = {
  type: typeof PATCH_DETAILS_ACTION,
  payload: {
    card_id: string,
    ordinagram_id: number,
    property: any
    type: string
  }
}

export const patchDetailsCardAction = (data: patchDetailsActionType) => ({
  type: data.type,
  payload: data.payload,
})