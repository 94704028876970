import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { connector } from "../../container";
import { Form, InputEmail, InputPassword, ErrorPopup } from "../../parts";
import { Button, Loader } from "../../../../ui-kit";
import { useFormWithValidation } from "../../../../utils/validation";
import { googleAuth } from "../../../../api/authApi";
import * as Image from "../../images";

export type Props = {
  loggedIn: () => void;
  setUserEmail: (email: string) => void;
  setGoogleLink: (link: any) => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  showError: (text: string) => void;
  hideError: () => void;
  authorization: any;
  title: string;
  buttonText: string;
  linkText: string;
  linkTo: string;
  handleAuthorization: any;
  children?: any;
};

const AuthForm = (props: Props) => {
  const {
    loggedIn,
    setUserEmail,
    setGoogleLink,
    setLoadingTrue,
    setLoadingFalse,
    showError,
    hideError,
    authorization,
    title,
    buttonText,
    linkText,
    linkTo,
    handleAuthorization,
    children,
  } = props;

  const history = useHistory();
  const { values, handleChange, errors, isValid, resetForm } =
    useFormWithValidation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingTrue();
    const { email, password } = values;
    handleAuthorization({ email, password }).then((res) => {
      if (res === "Пользователь с таким Email уже зарегистрирован") {
        setLoadingFalse();
        showError(res);
        setTimeout(() => {
          hideError();
        }, 2000);
      } else if (res === "Неверно указана почта или пароль") {
        setLoadingFalse();
        showError(res);
        setTimeout(() => {
          hideError();
        }, 2000);
      } else {
        loggedIn();
        setUserEmail(email);
        history.push("/home");
      }
    });
    resetForm();
  };

  const getGoogleLink = () => {
    googleAuth().then((res) => setGoogleLink(res));
  };

  useEffect(() => {
    getGoogleLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authorization.loading ? (
    <Loader />
  ) : (
    <>
      <Form
        linkTo={linkTo}
        linkText={linkText}
        title={title}
        onSubmit={handleSubmit}
      >
        <InputEmail onChange={handleChange} errorText={errors.email} />
        <InputPassword onChange={handleChange} errorText={errors.password} />
        {children}
        <Button
          type="submit"
          text={buttonText}
          className="authForm__button"
          disabled={!isValid}
        />
        <p className="authForm__text">или</p>
        <a
          href={authorization.googleLink}
          className="authForm__google-link"
        >
          <Image.Google />
          Войти с помощью Google
        </a>
      </Form>
      <ErrorPopup
        isOpen={authorization.isError}
        text={authorization.errorText}
      />
    </>
  );
};

export default connector(AuthForm);
