import produce from "immer";
import {cardType, connectLinesType} from "../../../../../types/types";
import {initStateType} from "../../reducer";
import {WritableDraft} from "immer/dist/types/types-external";
import {getCardsActionType} from "../../actions/diagramActions/getCards";
import { useTranslation } from "react-i18next";


export const getCardsReducer = (state: initStateType, action: getCardsActionType) =>
  produce(state, (draft: WritableDraft<initStateType>) => {
    const activeOrdmId = action.payload.ordinagramId
    const cards: cardType[] = action.payload.data.cards
    const connectionLines = action.payload.data.connection_lines
    const newCards: cardType[] = cards.map((item: cardType): cardType => {
      return {
        id: String(item?.id),// только строка! иначе между карточками не будут работать соединительные линии
        full_name: item.full_name,
        color_card: item.color_card,
        position: item.position,
        avatar_image: item.avatar_image,
        status: item.status,
        call_sign: item.call_sign,
        working_position: item.working_position,
        comments: item.comments,
        document_expired: item.document_expired,
        documents: item.documents,
        ordinagram_id: action.payload.ordinagramId,
        openMenuCard: item.openMenuCard ? item.openMenuCard : false,
        openMenuDetails: item.openMenuDetails ? item.openMenuDetails : false,
        inputFocus: item.inputFocus ? item.inputFocus : false,
        filterStatus: item.filterStatus || {value: '', opacity: 1},
        openComments: item.openComments || false,
        openDocument: item.openDocument || false,
        department: item.department || null,
      }
    })

    const newLines: connectLinesType[] = connectionLines.map((line: connectLinesType): connectLinesType => {
      return {
        id: String(line.id) || String(line.id),
        source: String(line.source),
        target: String(line.target),
        type: line.type,
        animated: line.animated || false,
        color: line.color,
      }
    })
    draft.cards = newCards
    draft.connectLines = newLines
    draft.activeOrdinagramId = activeOrdmId || null
    // draft.avatars = avatars
  })

