import React, { Component } from 'react'
import classnames from 'classnames'
import { DropdownItem } from './dropdown-item'
import styles from './dropdown.module.scss'

export class Dropdown extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.props.handleClose)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.props.handleClose)
  }

  render() {
    const { itemList, onSelectItem, top } = this.props

    return (
      <ul className={classnames('select__dropdown', styles.dropDown)} style={{ top: top || 34 }}>
        {itemList.map((item, id) => (
          <DropdownItem key={id} item={item} onSelectItem={() => onSelectItem(id)} />
        ))}
      </ul>
    )
  }
}
