import {call, all, put, takeEvery, select} from "@redux-saga/core/effects";
import {api} from "../../../../../api/restClient";
import {PATCH_CARD_ACTION, PATCH_CARD_SAGA, PATCH_CARD_STATUS_SAGA, PATCH_DETAILS_ACTION} from "../../actionTypes";
import {patchCardAction, patchCardTypeAction} from "../../actions/diagramActions/patchCardAction";
import {patchDetailsCardAction} from "../../actions/diagramActions/patchDetailsCardAction";


export const patchCard = {
  position: 'position',
  full_name: 'full_name',
  color_card: 'color_card',
  comments: 'comments',
  status: 'status',
  department: 'department',
}


const patchApiCard = (patchObj) => {
  return api.patch(`v1/organigram/${patchObj.ordinagram_id}/cards/${patchObj.card_id}/`, patchObj.property)
    .catch((err) => console.log(err))
}

const patchApiCardStatus = (patchObj) => {
  return api.patch(`v1/organigram/${patchObj.ordinagram_id}/cards/${patchObj.card_id}/status/`, patchObj.property)
    .catch((err) => console.log(err))
}

const shapingParams = (payload): patchCardTypeAction => {
  switch (payload.type) {
    case patchCard.position :
      const positionParams = {
        position: {
          x: Math.round(payload.property.x),
          y: Math.round(payload.property.y)
        }
      }
      return {...payload, property: positionParams}
    case patchCard.full_name: {
      const params = {full_name: payload.property}
      return {...payload, property: params}
    }
    case patchCard.color_card: {
      const params = {color_card: payload.property}
      return {...payload, property: params}
    }
    case patchCard.comments: {
      const params = {comments: payload.property}
      return {...payload, property: params}
    }
    case patchCard.status :
      const statusParams = {
        // status: {
        color_string: payload.property.color_string,
        opening_date: payload.property.opening_date,
        closing_date: payload.property.closing_date,
        status: payload.property.status,
        // }
      }
      return {...payload, property: statusParams}
    case patchCard.department:
      const params = {department: payload.property}
      return {...payload, property: params}
  }
}


function* patchCardAsync(action: patchCardTypeAction) {
  try {
    const obj = yield shapingParams(action.payload)
    yield call(patchApiCard, obj)
    yield put(patchCardAction({
      type: PATCH_CARD_ACTION,
      payload: obj
    }))
    yield put(patchDetailsCardAction({
      type: PATCH_DETAILS_ACTION,
      payload: obj,
    }))
  } catch (err) {
    console.log(err)
  }
}

export function* patchCardStatusAsync(action: patchCardTypeAction) {
  try {
    const obj = yield shapingParams(action.payload)
    yield call(patchApiCardStatus, obj)

    yield put(patchCardAction({
      type: PATCH_CARD_ACTION,
      payload: {
        ...obj,
        property: {
          status: {
            opening_date: obj.property.opening_date,
            closing_date: obj.property.closing_date,
            status: obj.property.status,
            color_string: obj.property.color_string,
          }
        },
      }
    }))
  } catch (err) {
    console.log(err)
  }
}

export function* patchCardSagas() {
  yield takeEvery(PATCH_CARD_SAGA, patchCardAsync)
  yield takeEvery(PATCH_CARD_STATUS_SAGA, patchCardStatusAsync)
}