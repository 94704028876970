import {all, put, call, takeEvery, select} from "redux-saga/effects";
import {api} from "../../../../../api/restClient";
import {detailsCardType} from "../../../../../types/detailsCardTypes";
import {getDetails, getDetailsCardType} from "../../actions/diagramActions/getDetailsCard";
import {DELETE_FIELD_SAGA, GET_DETAILS_CARD_ACTION} from "../../actionTypes";

const deleteApiField = (delObj) => {
  const {cardId, ordmId, fieldId} = delObj
  return api.delete(`v1/organigram/${ordmId}/cards/${cardId}/fields/${fieldId}/`)
    .catch((err) => console.log(err))
}


const getApiDetailsCard = (getObj) => {
  const {cardId, ordmId} = getObj
  return api.get(`v1/organigram/${ordmId}/cards/${cardId}/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}


function* getDet(getObj) {
  const details: detailsCardType = yield call(getApiDetailsCard, getObj)
  const getDetailsCardAction: getDetailsCardType = {
    type: GET_DETAILS_CARD_ACTION,
    payload: {
      card_id: getObj.cardId,
      ordinagram_id: getObj.ordmId,
      details: details,
      requestType: ""
    }
  }
  yield put(getDetails(getDetailsCardAction))
}

function* deleteFieldAsync (action) {
  const deleteObj = {
    cardId: action.payload.card_id,
    ordmId: action.payload.ordinagram_id,
    fieldId: action.payload.field_id
  }
  const getObj = {cardId: action.payload.card_id, ordmId: action.payload.ordinagram_id}
  yield call(deleteApiField, deleteObj)
  yield call(getDet, getObj)
}

export function* deleteFieldSagas () {
  yield takeEvery(DELETE_FIELD_SAGA, deleteFieldAsync)
}