import {GET_CARDS_ACTION} from "../../actionTypes";
import {avatarType, cardType, connectLinesType} from "../../../../../types/types";

export type getCardsActionType = {
  type: typeof GET_CARDS_ACTION
  payload: {
    data: {
      cards: cardType[]
      connection_lines: connectLinesType[]
    }
    ordinagramId: number
  }
}

export const getCardsAction = (data: getCardsActionType) => ({
  type: data.type,
  payload: data.payload
})