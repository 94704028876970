import React from "react";
import { CustomLink } from "../../../../ui-kit";
import "./form.scss";

export type Props = {
  linkText: string,
  title: string,
  linkTo: string,
  children: any,
  onSubmit?: (e: React.FormEvent) => void
}

export const Form = (props: Props) => {
  const { linkText, title, linkTo, children, onSubmit } = props;

  return (
    <form onSubmit={onSubmit} className="authForm" noValidate autoComplete="new-password">
      <h2 className="authForm__title">{title}</h2>
      {children}
      <CustomLink to={linkTo} text={linkText} className="authForm__link" />
    </form>
  );
};
