import { combineReducers } from 'redux'
import { homePageReducer } from '../pages/home/redux-sagas'
import { authorizationReducer } from '../pages/authorization/redux-sagas'


type appReducerType = typeof appReducer
export type appGlobalStore = ReturnType<appReducerType>

const appReducer = combineReducers({
  homePage: homePageReducer,
  authorization: authorizationReducer,
})

export const rootReducer = (state: any, action: any) => {
  return appReducer(state, action)
}
