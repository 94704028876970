import { useState, useEffect } from "react";
import "./checkbox.scss";

export type Props = {
  onChange: (e) => void
  ImageOn: any
  ImageOff: any
  disabled?: boolean
}

export const Checkbox = (props: Props) => {
  const { ImageOn, ImageOff, onChange, disabled } = props;

  const [defaultValue, setDefaultValue] = useState(false)

  const handleCheckChange = (e) => {
    setDefaultValue(e.target.checked)
    onChange(e.target.checked)
  }

  useEffect(() => {
    setDefaultValue(false)
  }, [disabled])

  return (
    <label className="custom-checkbox">
      <div className="customImg">{defaultValue ? <ImageOn /> : <ImageOff />}</div>
      <input
        className="checkbox"
        type="checkbox"
        checked={defaultValue}
        onChange={handleCheckChange}
        disabled={disabled}
      />
    </label>
  );
};