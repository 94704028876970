export const TOGGLE_LEFT_SIDE_PANEL = 'homePage/TOGGLE_LEFT_SIDE_PANEL'
export const TOGGLE_RIGHT_SIDE_PANEL = 'homePage/TOGGLE_RIGHT_SIDE_PANEL'

export const START_USER_CHECK = 'homePage/START_USER_CHECK'
export const FINISH_USER_CHECK = 'homePage/FINISH_UER_CHECK'

export const SET_USER_EMAIL = 'homePage/SET_USER_EMAIL'
export const SET_FULL_USER = 'homePage/SET_FULL_USER'

export const GET_ORDINAGRAMS_ACTION = 'homePage/GET_ORDINAGRAMS_ACTION'
export const GET_CARDS_ACTION = 'homePage/GET_CARDS_ACTION'
export const GET_DETAILS_CARD_ACTION = 'homePage/GET_DETAILS_CARD_ACTION'
export const CREATE_ORDINAGRAM_ACTION = 'homePage/CREATE_ORDINAGRAM_ACTION'
export const PATCH_CARD_ACTION = 'homePage/PATCH_CARD_ACTION'
export const GENERATE_POST_FIELDS_ACTION = 'homePage/GENERATE_POST_FIELDS_ACTION'
export const GET_DOCUMENTS_ACTION = 'homePage/GET_DOCUMENTS_ACTION'
export const PATCH_DETAILS_ACTION = 'homePage/PATCH_DETAILS_ACTION'

export const PREVIEW_PRINT_ACTION = 'homePage/PREVIEW_PRINT_ACTION'

// sagas type
export const POST_ORDINAGRAM_SAGA = 'homePage/POST_ORDINAGRAM_SAGA'
export const GET_ORDINAGRAM_SAGA = 'homePage/GET_ORDINAGRAM_SAGA'
export const GET_CARDS_SAGA = 'homePage/GET_CARDS_SAGA'
export const GET_DETAILS_CARD_SAGA = 'homePage/GET_DETAILS_CARD_SAGA'
export const PUT_DETAILS_CARD_SAGA = 'homePage/PUT_DETAILS_CARD_SAGA'
export const PUT_FIELDS_SAGA = 'homePage/PUT_FIELDS_SAGA'
export const PATCH_DETAILS_CARD_SAGA = 'homePage/PATCH_DETAILS_CARD_SAGA'
export const POST_CARD_SAGA = 'homePage/POST_CARD_SAGA'
export const PATCH_CARD_SAGA = 'homePage/PATCH_CARD_SAGA'
export const PATCH_CARD_STATUS_SAGA = 'homePage/PATCH_CARD_STATUS_SAGA'
export const POST_CONNECTION_LINE_SAGA = 'homePage/POST_CONNECTION_LINE'
export const DELETE_CARD_SAGA = 'homePage/DELETE_CARD_SAGA'
export const DELETE_ORDINAGRAM_SAGA = 'homePage/DELETE_ORDINAGRAM_SAGA'
export const DELETE_FIELD_SAGA = 'homePage/DELETE_FIELD_SAGA'
export const DELETE_DOCUMENT_SAGA = 'homePage/DELETE_DOCUMENT_SAGA'
export const DISCONNECT_CONNECTION_LINE_SAGA = 'homePage/DISCONNECT_CONNECTION_LINE_SAGA'
export const POST_AVATAR_SAGA = 'homePage/POST_AVATAR_SAGA'
export const POST_DOCUMENT_SAGA = 'homePage/POST_DOCUMENT_SAGA'
export const PATCH_DOCUMENT_SAGA = 'homePage/PATCH_DOCUMENT_SAGA'
