import * as t from '../../actionTypes'

export const setLoadingTrue = () => {
  return {
    type: t.SET_LOADING_TRUE,
  }
}

export const setLoadingFalse = () => {
  return {
    type: t.SET_LOADING_FALSE,
  }
}

